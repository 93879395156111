import React from 'react';
import PropTypes from 'prop-types';

import styles from './error.module.scss';

function Error({ text }) {
  return <div className={styles.error}>{text}</div>;
}

Error.propTypes = {
  text: PropTypes.string,
};

export default Error;
