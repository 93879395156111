import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import styles from './toast.module.scss';

toast.configure({
  position: 'top-center',
  autoClose: 6000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
  closeButton: false,
  toastClassName: styles.toast,
  bodyClassName: styles.body,
});

export const showToast = message => {
  return toast(message);
};
