import React from 'react';
import get from 'lodash/get';

import styles from './rich-text-components.module.scss';
import ArrowLink from '@/components/global/arrow-link/arrow-link';
import SectionIndicator, {
  ANIMATION_TYPES,
} from '@/components/global/section-indicator/section-indicator';
import Button, { MODIFIERS } from '@/components/global/button/button';
import Link from '@/components/global/link/link';
import ImageLinkList from '@/components/content/image-link-list/image-link-list';
import {
  EVENTS,
  CTA_TYPE,
} from '@/shared/hooks/use-tracking/data-layer-variables';
import { transformData } from '@/services/contentful/data-transform';

export function SectionHeading(node) {
  const textValue = get(node, 'content[0].value', '');

  return (
    <div className={styles.sectionHeading}>
      <SectionIndicator
        headline={textValue}
        animationType={ANIMATION_TYPES.ONLY_HORIZONTAL}
      />
    </div>
  );
}

export function ExternalLink(node) {
  const url = get(node, 'data.uri', '');
  const labelText = get(node, 'content[0].value', '');

  return (
    <span className={styles.externalLink}>
      <ArrowLink
        label={labelText}
        url={url}
        trackingData={{
          event: EVENTS.CLICK_CTA_PAKCON,
          ctaType: CTA_TYPE.PAKCON_2019,
          label: labelText,
        }}
        isExternalUrl
      />
    </span>
  );
}

export function Entry(node) {
  const contentTypeId = get(node, 'data.target.sys.contentType.sys.id', null);

  if (contentTypeId === 'link') {
    const link = get(node, 'data.target.fields', {});
    return <CallToAction link={link} />;
  }

  if (contentTypeId === 'imageLinkList') {
    const links = get(transformData(node), 'data.target.images');

    return (
      <div className={styles.sponsors}>
        <ImageLinkList links={links} />
      </div>
    );
  }

  throw new Error('Invalid entry content type');
}

export function LineBreak() {
  return <br />;
}

/* eslint-disable */
function CallToAction({ link }) {
  const { label, url } = link;

  return (
    <div className={styles.cta}>
      <Link
        href={url}
        trackingData={{
          event: EVENTS.CLICK_CTA_PAKCON,
          ctaType: CTA_TYPE.PAKCON_APPLY,
          label,
        }}
        isExternalUrl
      >
        <a className={styles.ctaLink}>
          <Button
            title={label}
            type="submit"
            modifiers={[
              MODIFIERS.HOVER_BLUE,
              MODIFIERS.SECONDARY,
              MODIFIERS.LARGE,
            ]}
          />
        </a>
      </Link>
    </div>
  );
}
/* eslint-enable */
