export function createLayout(contentComponents) {
  const [heroSection, ...rest] = _groupComponentsBySections(contentComponents);

  if (heroSection[0].type.displayName === 'ContentItemDetail') {
    return { contentItemDetailSection: heroSection };
  }

  return {
    heroSection,
    contentSections: rest.map(([indicator, ...rest]) => ({
      indicator,
      subSections: rest,
    })),
  };
}

function _groupComponentsBySections(contentComponents = []) {
  return contentComponents.reduce(
    (currentSections, component) => {
      if (
        !currentSections.length ||
        component.type.displayName === 'SectionIndicator'
      ) {
        currentSections.push([]);
      }

      const lastSection = currentSections[currentSections.length - 1];
      const restOfSections = currentSections.slice(
        0,
        currentSections.length - 1
      );

      return [...restOfSections, lastSection.concat(component)];
    },

    []
  );
}
