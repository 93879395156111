import React from 'react';
import PropTypes from 'prop-types';

import { KnowledgeItemShape } from '../../../shared/shapes';
import { PRESS } from '../../../shared/content';
import {
  EVENTS,
  CTA_TYPE,
} from '../../../shared/hooks/use-tracking/data-layer-variables';

import ContentItemGrid from '../../../components/global/content-item-grid/content-item-grid';
import ButtonLink from '../../../components/global/button-link/button-link';

import styles from './press-release-section.module.scss';

function PressReleaseSection({ items = [] }) {
  return (
    <section className={styles.pressReleaseSection}>
      <ContentItemGrid
        items={items}
        trackingDataEvent={EVENTS.CLICK_CTA_PRESS}
      />
      <div className={styles.overviewLinkWrapper}>
        <ButtonLink
          url="/press/releases"
          label={PRESS.OVERVIEW_LINK}
          modifier="white"
          trackingData={{
            event: EVENTS.CLICK_CTA_PRESS,
            ctaType: CTA_TYPE.PRESS_SEE_ALL_ARTICLES,
          }}
        />
      </div>
    </section>
  );
}

PressReleaseSection.propTypes = {
  items: PropTypes.arrayOf(KnowledgeItemShape),
};

export default PressReleaseSection;
