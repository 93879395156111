import React from 'react';
import nl2br from 'react-nl2br';
import classNames from 'classnames';

import { toPresentableDate } from '../../../../shared/utils/date/date';
import { KnowledgeItemShape } from '../../../../shared/shapes';
import {
  EVENTS,
  CTA_TYPE,
} from '../../../../shared/hooks/use-tracking/data-layer-variables';

import Link from '../../../../components/global/link/link';
import { getContentItemUrl } from '../../../../components/global/content-item-grid/content-item-grid';

import styles from './knowledge-hero-item.module.scss';

function KnowledgeHeroItem({ item }) {
  const url = getContentItemUrl(item.link, item.slug, item.type);

  return (
    <div
      className={classNames(
        styles.knowledgeHeroItem,
        styles[item.mediaType.toLowerCase()]
      )}
    >
      <div className={styles.itemDetailsArea}>
        <p className={styles.mediaSubtype}>{item.mediaSubtype}</p>
        {item.date && (
          <p className={styles.itemDate}>{toPresentableDate(item.date)}</p>
        )}
        {item.subheadline && <p className={styles.dotSeparator}>&middot;</p>}
        <p className={styles.subheadline}>{item.subheadline}</p>
      </div>

      <div className={styles.headlineArea}>
        <Link
          href={url}
          isExternalUrl={item.link && item.link.isExternalUrl}
          trackingData={{
            event: EVENTS.CLICK_CTA_KNOWLEDGE,
            ctaType: CTA_TYPE.KNOWLEDGE_HERO,
            label: item.headline,
          }}
        >
          <a className={styles.headlineLink}>
            <h2 className={styles.headline}>{nl2br(item.headline)}</h2>
          </a>
        </Link>
      </div>
    </div>
  );
}

KnowledgeHeroItem.propTypes = {
  item: KnowledgeItemShape,
};

export default KnowledgeHeroItem;
