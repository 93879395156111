import React from 'react';
import PropTypes from 'prop-types';

import { KnowledgeItemShape } from '../../../shared/shapes';
import { PRESS } from '../../../shared/content';
import Arrow from '../../../shared/icons/short-arrow.svg';
import {
  EVENTS,
  CTA_TYPE,
} from '../../../shared/hooks/use-tracking/data-layer-variables';

import ContentItemGrid from '../../../components/global/content-item-grid/content-item-grid';
import Link from '../../../components/global/link/link';

import styles from './press-release-overview.module.scss';

function PressReleaseOverview({ items = [] }) {
  return (
    <div className={styles.pressReleaseOverview}>
      <Link
        href="/press"
        trackingData={{
          event: EVENTS.CLICK_CTA_PRESS,
          ctaType: CTA_TYPE.PRESS_OVERVIEW_BACK,
        }}
      >
        <a className={styles.pressLink}>
          <Arrow height={16} className={styles.arrow} />
          <span>{PRESS.PRESS_LINK}</span>
        </a>
      </Link>
      <ContentItemGrid
        items={items}
        trackingDataEvent={EVENTS.CLICK_CTA_PRESS_CONTENT}
      />
    </div>
  );
}

PressReleaseOverview.propTypes = {
  items: PropTypes.arrayOf(KnowledgeItemShape),
};

export default PressReleaseOverview;
