import React from 'react';
import { JSONLD, Generic } from 'react-structured-data';
import NextHead from 'next/head';
import getConfig from 'next/config';
import classNames from 'classnames';

import styles from './job.module.scss';

import { GreenhouseDataShape } from '@/shared/shapes';
import { useTranslatedContent, JOB_POST } from '@/shared/content';
import useTracking from '@/shared/hooks/use-tracking/use-tracking';
import {
  EVENTS,
  CTA_TYPE,
} from '@/shared/hooks/use-tracking/data-layer-variables';

import Teaser from '@/components/content/teaser/teaser';
import SectionIndicator from '@/components/global/section-indicator/section-indicator';
import GreenhouseForm from '@/components/content/job/greenhouse-form/greenhouse-form';
import Link from '@/components/global/link/link';

const {
  publicRuntimeConfig: { BASE_URL },
} = getConfig();

const EMPLOYMENT_TYPE_MAPPING = {
  'Full-time': 'FULL_TIME',
  Intern: 'INTERN',
  'Working Student': 'PART_TIME',
  Freelance: 'CONTRACTOR',
};

const mapEmploymentType = employmentType =>
  EMPLOYMENT_TYPE_MAPPING[employmentType];

function Job({
  greenhouseData: {
    jobPostId,
    title,
    departments,
    location,
    employmentType,
    seniorityLevel,
    content,
    relatedJobPosts,
    lang,
    questions = [],
    firstPublishedAt,
    organization,
  },
}) {
  const t = useTranslatedContent(JOB_POST, lang);

  useTracking({
    event: EVENTS.JOB_DESCRIPTION_START,
    jobName: title,
    speciality: departments,
    seniorityLevel,
    employmentType,
    organization,
    location,
  });

  const teaserCTA = {
    contentType: 'link',
    label: 'Apply now',
    isExternalUrl: false,
    url: '#greenhouse-form',
  };
  return (
    <>
      <NextHead>
        {relatedJobPosts.map(({ lang, url }, index) => {
          return (
            <link
              key={index}
              rel="alternate"
              hrefLang={lang.toLowerCase()}
              href={`${BASE_URL}${url}`}
            />
          );
        })}
      </NextHead>

      <Teaser
        fullscreen={true}
        headline={title}
        about={`${departments} · ${location} · ${employmentType} · ${seniorityLevel}`}
        cta={teaserCTA}
        ctaType="Button"
      />
      <div className={styles.indicatorWrapper}>
        <SectionIndicator headline={'Job description'} animationType={'full'} />

        <div className={styles.langLinks}>
          {relatedJobPosts.map(({ url, lang: relatedLang }, index) => {
            return (
              <div
                key={index}
                className={classNames(styles.langLink, {
                  [styles.langLinkActive]: lang === relatedLang,
                })}
              >
                <Link
                  href={url}
                  trackingData={{
                    event: EVENTS.CLICK_CTA_JOB,
                    ctaType: CTA_TYPE.JOB_LANGUAGE_CHANGE,
                    label: relatedLang,
                  }}
                >
                  <a className={styles.langLinkInner}>{relatedLang}</a>
                </Link>
              </div>
            );
          })}
        </div>
      </div>

      <div className={styles.job}>
        <div
          className={styles.content}
          dangerouslySetInnerHTML={{ __html: content }}
        />

        <div
          className={styles.companyInfo}
          dangerouslySetInnerHTML={{
            __html: t.JOB_POST_INTRO,
          }}
        />
      </div>

      {questions.length && (
        <>
          <div id={'greenhouse-form'} className={styles.applyIndicatorWrapper}>
            <SectionIndicator
              headline={'Apply now'}
              animationType={'onlyHorizontal'}
            />
          </div>
          <div className={styles.job}>
            <GreenhouseForm
              jobPostId={jobPostId}
              questions={questions}
              lang={lang}
              trackingData={{
                jobName: title,
                speciality: departments,
                seniorityLevel,
                employmentType,
                organization,
                location,
              }}
            />
          </div>
        </>
      )}

      <JSONLD dangerouslyExposeHtml={true}>
        <Generic
          type="JobPosting"
          jsonldtype="JobPosting"
          schema={{
            title,
            datePosted: firstPublishedAt,
            employmentType: mapEmploymentType(employmentType),
            description: content,
          }}
        >
          <Generic
            type="hiringOrganization"
            jsonldtype="Organization"
            schema={{
              name: 'Project A',
              sameAs: BASE_URL,
              logo: `${BASE_URL}/favicon-192x192.jpg`,
            }}
          />

          <Generic type="jobLocation" jsonldtype="Place">
            <Generic
              type="address"
              jsonldtype="PostalAddress"
              schema={{ addressLocality: location }}
            />
          </Generic>
        </Generic>
      </JSONLD>
    </>
  );
}

Job.propTypes = {
  greenhouseData: GreenhouseDataShape,
};

export default Job;
