import { get } from 'lodash';
import { flatMap, forEach, isArray } from 'lodash/fp';
import { slugify } from '../../shared/utils/url/url';

export function getFilterGroups({ items, filterGroupsConfig }) {
  return filterGroupsConfig.map(filter => {
    return {
      ...filter,
      active: !!filter.active,
      enabled: true,
      options: _getFilterOptionsFromItems(filter.property, items),
    };
  });
}

/**
 *
 * @param object item - an object that contains a @param property.
 * The value returned by accessing the property may or may not be an array
 * to take into account items that belong to one or more filters.
 * The function handles both cases internally.
 *
 * @param string property
 */
function getSplitValues(item, property) {
  const propertyValues = get(item, property);
  const values = isArray(propertyValues) ? propertyValues : [propertyValues];
  return flatMap(_splitMultiLineValue, values);
}

export function applyFilterGroups(filters, items) {
  return items.filter(item => {
    return filters.reduce((groupsPredicate, { property, options }) => {
      const noActiveOptions = options.every(({ active }) => !active);

      return (
        groupsPredicate &&
        options.reduce((groupPredicate, { label, active }) => {
          return (
            groupPredicate ||
            (active &&
              getSplitValues(item, property).some(
                propertyValue => propertyValue === label
              ))
          );
        }, noActiveOptions)
      );
    }, true);
  });
}

export function updateFilterGroups(filterGroups, items) {
  return filterGroups.map(filterGroup => {
    const otherFilterGroups = _removeItem(filterGroups, filterGroup);
    const predictedItems = applyFilterGroups(otherFilterGroups, items);

    return {
      ...filterGroup,
      options: filterGroup.options.map(option => {
        return {
          ...option,
          enabled: _itemsContainValue({
            items: predictedItems,
            property: filterGroup.property,
            value: option.label,
          }),
        };
      }),
    };
  });
}

function _splitMultiLineValue(value = '') {
  const firstLine = value.split('\n')[0];
  const bracketMatches = firstLine.match(/\((.+)\)/);

  return [bracketMatches ? bracketMatches[1] : firstLine];
}

function _removeItem(items, itemToRemove) {
  return items.filter(item => item !== itemToRemove);
}

function _itemsContainValue({ items, property, value }) {
  return items.some(item => {
    return getSplitValues(item, property).some(
      propertyValue => propertyValue === value
    );
  });
}

function _getFilterOptionsFromItems(property, items) {
  return Object.values(
    items.reduce((carry, item) => {
      forEach(value => {
        if (value) {
          carry[value] = {
            value: slugify(value),
            label: value,
            enabled: true,
            active: !!item.active,
          };
        }
      }, getSplitValues(item, property));
      return carry;
    }, {})
  ).sort((a, b) => (a.label > b.label ? 1 : -1));
}
