import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { KpiShape } from '../../../shared/shapes';

import styles from './slider-line.module.scss';

const REPETITION_RATE = 4;

function SliderLine({ content, slide }) {
  const repeatedContent = [...Array(REPETITION_RATE)].reduce(
    arr => [...arr, ...content],
    []
  );

  return (
    <div
      className={classNames(styles.line, styles[slide])}
      style={{ animationDuration: `${REPETITION_RATE * 12.5}s` }}
    >
      {repeatedContent.map(({ number, text }, i, arr) => {
        const isHighlighted = i % (arr.length / REPETITION_RATE) === 0;

        return (
          <React.Fragment key={i}>
            <span
              className={classNames(styles.number, {
                [styles.highlighted]: isHighlighted,
              })}
            >
              {number}
            </span>
            <span
              className={classNames(styles.text, {
                [styles.highlighted]: isHighlighted,
              })}
            >
              {text}
            </span>
          </React.Fragment>
        );
      })}
    </div>
  );
}

SliderLine.propTypes = {
  content: KpiShape.isRequired,
  slide: PropTypes.oneOf(['left', 'right']).isRequired,
};

export default SliderLine;
