import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import camelCase from 'lodash/camelCase';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import {
  EVENTS,
  CTA_TYPE,
} from '../../../shared/hooks/use-tracking/data-layer-variables';
import ArrowLink from '../../global/arrow-link/arrow-link';
import Slider from '../../global/slider/slider';

import { LinkShape, MediaShape, RichTextShape } from '../../../shared/shapes';
import { getTransformationMediaUrl } from '../../../shared/utils/url/url';

import styles from './claim-slider.module.scss';

const getSvgProperties = style => {
  if (style === 'slidesLeft') {
    return {
      x: '100%',
      textAnchor: 'end',
    };
  }

  return {
    x: '0%',
    textAnchor: 'start',
  };
};

function ClaimSlider({ headline, content, link, slides, style }) {
  return (
    <section className={styles.claimSlider}>
      <div className={classNames(styles.inner, styles[camelCase(style)])}>
        <svg viewBox="0 0 1000 250" className={styles.headline}>
          <text
            y="50%"
            alignmentBaseline="central"
            {...getSvgProperties(camelCase(style))}
          >
            {headline}
          </text>
        </svg>
        <div className={styles.imageSlider}>
          {slides && (
            <Slider trackingEventType={EVENTS.CLICK_CTA_HOMEPAGE}>
              {slides.map(slide => (
                <picture key={slide.id}>
                  <source
                    srcSet={getTransformationMediaUrl(slide, { fm: 'webp' })}
                    type="image/webp"
                  />
                  <img
                    src={getTransformationMediaUrl(slide, {
                      fm: 'jpg',
                      bg: 'rgb:000000',
                    })}
                    alt=""
                  />
                </picture>
              ))}
            </Slider>
          )}
        </div>

        <div className={styles.content}>
          {documentToReactComponents(content)}

          {link && (
            <div className={styles.link}>
              <ArrowLink
                {...link}
                trackingData={{
                  event: EVENTS.CLICK_CTA_HOMEPAGE,
                  ctaType: CTA_TYPE.HOMEPAGE_SECTION_LINK,
                  label: link.label,
                }}
              />
            </div>
          )}
        </div>
      </div>
    </section>
  );
}

ClaimSlider.propTypes = {
  headline: PropTypes.string,
  content: RichTextShape,
  link: LinkShape,
  slides: PropTypes.arrayOf(MediaShape),
  style: PropTypes.string,
};

export default ClaimSlider;
