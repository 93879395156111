import React from 'react';
import { arrayOf } from 'prop-types';

import { LinkShape } from '../../../shared/shapes';
import { EVENTS } from '../../../shared/hooks/use-tracking/data-layer-variables';

import Link from '../../global/link/link';
import styles from './image-link-list.module.scss';

function ImageLinkList({ links }) {
  return (
    <div className={styles.wrapper}>
      <ul className={styles.imageLinkList}>
        {links &&
          links.map(link => (
            <li key={link.id} className={styles.imageLinkItem}>
              <Link
                href={link.url}
                {...link}
                isExternalUrl={link.isExternalUrl}
                trackingData={{
                  event: EVENTS.CLICK_IMAGE,
                  ctaType: '',
                  label: link.name,
                }}
              >
                <a>
                  <picture>
                    <source srcSet={link.icon.file.url} />
                    <img alt={link.icon.title} src={link.icon.file.url} />
                  </picture>
                </a>
              </Link>
            </li>
          ))}
      </ul>
    </div>
  );
}

ImageLinkList.propTypes = {
  links: arrayOf(LinkShape),
};

export default ImageLinkList;
