import React from 'react';
import nl2br from 'react-nl2br';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import { VentureShape } from '../../../../../shared/shapes';
import {
  EVENTS,
  CTA_TYPE,
} from '../../../../../shared/hooks/use-tracking/data-layer-variables';

import ArrowLink from '../../../../../components/global/arrow-link/arrow-link';
import Link from '../../../../../components/global/link/link';

import styles from './venture-details.module.scss';

function VentureDetails({ venture }) {
  const trackingData = {
    event: EVENTS.CLICK_VENTURE,
    label: (venture.website && venture.website.label) || '',
  };

  return (
    <article className={styles.ventureDetails}>
      <header className={styles.header}>
        <h2 className={styles.headline}>{venture.headline}</h2>

        <div className={styles.subline}>
          <h3 className={styles.subheadline}>{venture.subheadline}</h3>

          {venture.website && (
            <ArrowLink
              {...venture.website}
              trackingData={{
                ...trackingData,
                ctaType: CTA_TYPE.VENTURE_WEBSITE_HEADER_LINK,
              }}
            />
          )}
        </div>
      </header>

      <main className={styles.main}>
        <section className={styles.highlightListSection}>
          <dl className={styles.properties}>
            {venture.founded && (
              <div className={styles.property}>
                <dt className={styles.propertyName}>Founded</dt>
                <dd className={styles.propertyValue}>{venture.founded}</dd>
              </div>
            )}
            <div className={styles.property}>
              <dt className={styles.propertyName}>Management</dt>
              <dd className={styles.propertyValue}>
                {nl2br(venture.management)}
              </dd>
            </div>

            <div className={styles.property}>
              <dt className={styles.propertyName}>Location</dt>
              <dd className={styles.propertyValue}>
                {nl2br(venture.location)}
              </dd>
            </div>

            <div className={styles.property}>
              <dt className={styles.propertyName}>Segment</dt>
              <dd className={styles.propertyValue}>{nl2br(venture.segment)}</dd>
            </div>

            <div className={styles.property}>
              <dt className={styles.propertyName}>
                Project&nbsp;A Investment Stage
              </dt>
              <dd className={styles.propertyValue}>{venture.investment}</dd>
            </div>

            {venture.website && (
              <div className={styles.property}>
                <dt className={styles.propertyName}>Website</dt>
                <dd className={styles.propertyValue}>
                  <Link
                    {...venture.website}
                    href={venture.website.url}
                    trackingData={{
                      ...trackingData,
                      ctaType: CTA_TYPE.VENTURE_WEBSITE_INFO_LINK,
                    }}
                  >
                    <a>{venture.website.label}</a>
                  </Link>
                </dd>
              </div>
            )}
          </dl>
        </section>

        <section className={styles.contentSection}>
          {venture.highlightContent && (
            <div className={styles.highlightContentSubSection}>
              {documentToReactComponents(venture.highlightContent)}
            </div>
          )}

          <div className={styles.extraContentSubSection}>
            {documentToReactComponents(venture.content)}
          </div>
        </section>
      </main>
    </article>
  );
}

VentureDetails.propTypes = {
  venture: VentureShape,
};

export default VentureDetails;
