import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { FilterGroupShape } from '../../../../shared/shapes';
import { slugify } from '../../../../shared/utils/url/url';
import useTracking from '../../../../shared/hooks/use-tracking/use-tracking';
import {
  EVENTS,
  CTA_TYPE,
} from '../../../../shared/hooks/use-tracking/data-layer-variables';

import FilterDropdown from './filter-dropdown/filter-dropdown';

import styles from './job-table-filters.module.scss';

function JobTableFilters({ filterGroups, actions }) {
  const { pushTracking } = useTracking();

  const handleChange = (group, selectedOption) => {
    pushTracking({
      event: EVENTS.SELECT_FILTER,
      ctaType: CTA_TYPE.CAREER_FILTER,
      label: selectedOption.value,
      group: group.label,
    });

    actions.toggleFilterOption({
      group,
      option: selectedOption,
      exclusive: true,
    });
  };

  const [filtersVisible, setFilterVisible] = useState(false);

  return (
    <tbody
      className={classNames(styles.jobTableFilters, {
        [styles.visible]: filtersVisible,
      })}
    >
      <tr className={styles.row}>
        <td className={styles.toggle}>
          <button
            className={styles.toggleButton}
            onClick={() => {
              pushTracking({
                event: EVENTS.TOGGLE_FILTER_GROUP,
                ctaType: CTA_TYPE.CAREER_FILTER,
                areFiltersVisible: !filtersVisible,
              });
              setFilterVisible(!filtersVisible);
            }}
          >
            <span>Filtering</span>
            <div className={styles.toggleIcon} />
          </button>
        </td>
      </tr>

      <tr className={classNames(styles.row, styles.filters)}>
        {filterGroups.map(group => {
          const groupKey = slugify(group.label);

          return (
            <th className={styles.head} key={groupKey}>
              <FilterDropdown
                key={groupKey}
                group={group}
                handleChange={handleChange}
              />
            </th>
          );
        })}
      </tr>
    </tbody>
  );
}

JobTableFilters.propTypes = {
  filterGroups: PropTypes.arrayOf(FilterGroupShape),
  actions: PropTypes.objectOf(PropTypes.func),
};

export default JobTableFilters;
