import React from 'react';
import PropTypes from 'prop-types';

import { FilterGroupShape, VentureShape } from '../../../shared/shapes';
import { useFilters } from '../../../shared/hooks/use-filters/use-filters';
import { CTA_TYPE } from '../../../shared/hooks/use-tracking/data-layer-variables';

import ExpandingFilters from '../../../components/global/expanding-filters/expanding-filters';
import VentureGrid from './venture-grid/venture-grid';

import styles from './venture-section.module.scss';

function VentureSection({ items: initialItems, initialFilterGroups }) {
  const [filterGroups, items, actions] = useFilters(
    initialFilterGroups,
    initialItems
  );

  return (
    <section className={styles.ventureSection}>
      <ExpandingFilters
        filterGroups={filterGroups}
        actions={actions}
        trackingCtaType={CTA_TYPE.FILTERS_VENTURES}
      />
      <VentureGrid items={items} />
    </section>
  );
}

VentureSection.propTypes = {
  items: PropTypes.arrayOf(VentureShape),
  initialFilterGroups: PropTypes.arrayOf(FilterGroupShape),
};

export default VentureSection;
