import PropTypes from 'prop-types';
import React from 'react';
import {
  CTA_TYPE,
  EVENTS,
} from '../../../shared/hooks/use-tracking/data-layer-variables';
import Arrow from '../../../shared/icons/long-arrow.svg';
import { toPresentableDate } from '../../../shared/utils/date/date';
import Link from '../../global/link/link';
import styles from './customizable-list.module.scss';

function ContentItemCustomizableList({ content, link, linkLabel }) {
  return (
    <section className={styles.wrapper}>
      <ul className={styles.list}>
        {content.map(c => {
          return (
            <li key={c.id}>
              <Link
                href={`/${c.slug}`}
                isExternalUrl={true}
                trackingData={{
                  event: EVENTS.CLICK_CONTENT_LINK,
                  ctaType: CTA_TYPE.CONTENT_ITEM_LINK,
                  title: c.headline,
                  topic: c.topic,
                  media: c.mediaType,
                }}
              >
                <a className={styles.row}>
                  <div className={styles.content}>
                    <time className={styles.date}>
                      {toPresentableDate(c.date)}
                    </time>
                    <p className={styles.title}>{c.headline}</p>
                  </div>
                  <div className={styles.arrow}>
                    <Arrow />
                  </div>
                </a>
              </Link>
            </li>
          );
        })}
      </ul>

      {link && (
        <div className={styles.overviewLinkWrapper}>
          <Link
            href={link}
            trackingData={{
              event: EVENTS.CLICK_CONTENT_LINK,
              ctaType: CTA_TYPE.CONTENT_ITEM_SEE_ALL,
              label: linkLabel,
            }}
          >
            <a className={styles.overviewLink}>{linkLabel || 'See all'}</a>
          </Link>
        </div>
      )}
    </section>
  );
}

ContentItemCustomizableList.propTypes = {
  name: PropTypes.string,
  content: PropTypes.array,
  link: PropTypes.string,
  linkLabel: PropTypes.string,
};

ContentItemCustomizableList.displayName = 'CustomizableList';

export default ContentItemCustomizableList;
