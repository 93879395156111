import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Link from '../../../components/global/link/link';

import styles from './button-link.module.scss';

function ButtonLink(props) {
  const { label, url, modifier = 'blue', ...rest } = props;

  return (
    <Link {...rest} href={url}>
      <a className={classNames(styles.buttonLink, styles[modifier])}>
        <span>{label}</span>
      </a>
    </Link>
  );
}

ButtonLink.propTypes = {
  url: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  modifier: PropTypes.oneOf(['white', 'blue']),
};

export default ButtonLink;
