import React from 'react';
import { KpiShape } from '../../../shared/shapes';
import SliderLine from './slider-line';

import styles from './kpi-slider.module.scss';

function KpiSlider({ content }) {
  const firstLine = content.slice(0, content.length / 2);
  const secondLine = content.slice(content.length / 2);

  return (
    <div className={styles.kpiSlider}>
      <SliderLine content={firstLine} slide="left" />
      <SliderLine content={secondLine} slide="right" />
    </div>
  );
}

KpiSlider.propTypes = {
  content: KpiShape.isRequired,
};

export default KpiSlider;
