import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';

import { GreenhouseQuestionShape } from '../../../../../shared/shapes';
import {
  useTranslatedContent,
  APPLICATION_FORM,
} from '../../../../../shared/content';

import CaretDownIcon from '../../../../../shared/icons/caret-down.svg';
import Label from '../label/label';
import Error from '../error/error';

import styles from './dropdown-input.module.scss';

const getOptionLabel = (questionValue, availableValues) => {
  const option = availableValues.find(
    ({ value }) => String(questionValue) === String(value)
  );

  return option ? option.label : '';
};

function DropdownInput({ question, lang }) {
  const {
    required,
    description,
    label,
    name,
    value: questionValue,
    values: questionValues,
  } = question;

  const t = useTranslatedContent(APPLICATION_FORM, lang);

  const [valueLabel, setValueLabel] = useState(questionValue);

  const {
    register,
    watch,
    formState: { errors: stateErrors },
  } = useFormContext();
  const error = stateErrors[name];

  const currentSelectedValue = watch(name);
  useEffect(() => {
    const valueLabel = getOptionLabel(currentSelectedValue, questionValues);
    setValueLabel(valueLabel);
  }, [currentSelectedValue, questionValues]);

  return (
    <div className={styles.dropdownInput}>
      <div className={styles.label}>
        <label className={styles.labelText} htmlFor={name}>
          <Label text={description || label} required={required} lang={lang} />
        </label>

        <div className={styles.inputWrapper}>
          <select
            id={name}
            defaultValue={questionValue}
            className={classNames(styles.input, { [styles.hasError]: error })}
            {...register(name, {
              required: required && t.VALIDATION_REQUIRED,
            })}
          >
            <option value="">{t.SELECT_INPUT_CTA}</option>

            {questionValues.map(({ value, label }, index) => (
              <option key={index} value={value}>
                {label}
              </option>
            ))}
          </select>

          <div className={styles.inputValue}>
            {valueLabel}

            {!valueLabel && (
              <div className={styles.placeholder}>{t.SELECT_INPUT_CTA}</div>
            )}
            <div className={styles.icon}>
              <CaretDownIcon width={12} />
            </div>
          </div>
        </div>
      </div>

      {error && <Error text={error.message} />}
    </div>
  );
}

DropdownInput.propTypes = {
  question: GreenhouseQuestionShape,
  lang: PropTypes.oneOf(['EN', 'DE']),
};

export default DropdownInput;
