import React from 'react';
import PropTypes from 'prop-types';
import { DownloadElementShape } from '../../../shared/shapes';
import { getTransformationMediaUrl } from '../../../shared/utils/url/url';
import Download from '../../../shared/icons/download.svg';
import useTracking from '../../../shared/hooks/use-tracking/use-tracking';
import {
  EVENTS,
  CTA_TYPE,
} from '../../../shared/hooks/use-tracking/data-layer-variables';

import styles from './press-kit-list.module.scss';

const PORTRAIT_CONFIG = {
  w: 79,
  fm: 'png',
};

function PressKitList({ items = [] }) {
  const { pushTracking } = useTracking();

  return (
    <div className={styles.downloadList}>
      {items.map(({ id, headline, contentDescription, picture, files }) => (
        <div key={id} className={styles.downloadTile}>
          <a
            href={files[0].file.url}
            className={styles.downloadLink}
            onClick={() => {
              pushTracking({
                event: EVENTS.CLICK_PRESS_ASSETS,
                ctaType: CTA_TYPE.DOWNLOAD,
                label: headline,
              });
            }}
          >
            <div className={styles.imageWrapper}>
              <picture>
                <source
                  srcSet={getTransformationMediaUrl(picture, PORTRAIT_CONFIG)}
                />
                <img
                  src={getTransformationMediaUrl(picture, PORTRAIT_CONFIG)}
                  alt={picture.description}
                />
              </picture>
              <div className={styles.overlay} />
            </div>
            <div className={styles.textWrapper}>
              <div className={styles.headline}>{headline}</div>
              <div className={styles.description}>{contentDescription}</div>
              <Download width={12} className={styles.downloadIcon} />
            </div>
          </a>
        </div>
      ))}
    </div>
  );
}

PressKitList.propTypes = {
  items: PropTypes.arrayOf(DownloadElementShape),
};

export default PressKitList;
