import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Link from '../../../components/global/link/link';
import Arrow from '../../../shared/icons/short-arrow.svg';

import styles from './arrow-link.module.scss';

function ArrowLink(props) {
  const { onMouseEnter, onMouseLeave, label, url, ...rest } = props;

  return (
    <Link {...rest} href={url}>
      <a
        className={classNames(styles.arrowLink, styles.active)}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <span className={styles.label}>{label}</span>
        <span className={styles.arrow}>
          <Arrow width={12} />
        </span>
      </a>
    </Link>
  );
}

ArrowLink.propTypes = {
  url: PropTypes.string,
  label: PropTypes.string,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
};

export default ArrowLink;
