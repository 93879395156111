import { useState } from 'react';

import {
  applyFilterGroups,
  updateFilterGroups,
} from '../../../services/filter/filter-service';
import {
  getToggledFilterGroup,
  getToggledFilterOption,
} from './filter-transformations';

export const useFilters = (initialFilterGroups, initialItems) => {
  const [filterGroups, setFilterGroups] = useState(initialFilterGroups);
  const [items, setItems] = useState(initialItems);

  const toggleFilterGroup = group => {
    setFilterGroups(
      getToggledFilterGroup({
        filterGroups,
        group,
      })
    );
  };

  const toggleFilterOption = ({ group, option, exclusive = false }) => {
    const selectedFilters = getToggledFilterOption({
      filterGroups,
      group,
      option,
      exclusive,
    });
    setFilterGroups(selectedFilters);

    const items = applyFilterGroups(selectedFilters, initialItems);
    const modifiedFilterGroups = updateFilterGroups(
      selectedFilters,
      initialItems
    );

    setItems(items);
    setFilterGroups(modifiedFilterGroups);
  };

  const actions = { toggleFilterGroup, toggleFilterOption };

  return [filterGroups, items, actions];
};
