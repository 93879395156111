import React from 'react';
import PropTypes from 'prop-types';

import {
  useTranslatedContent,
  APPLICATION_FORM,
} from '../../../../../shared/content';
import {
  CTA_TYPE,
  EVENTS,
} from '../../../../../shared/hooks/use-tracking/data-layer-variables';

import ArrowLink from '../../../../global/arrow-link/arrow-link';
import styles from './confirmation-form.module.scss';

function ConfirmationForm({ lang }) {
  const t = useTranslatedContent(APPLICATION_FORM, lang);

  return (
    <section className={styles.confirmationForm}>
      <h3 className={styles.headline}>{t.JOB_SUBMISSION_SUCCESS}</h3>
      <ArrowLink
        label={t.JOB_SUBMISSION_SUCCESS_CTA}
        url={'/careers'}
        trackingData={{
          event: EVENTS.CLICK_CTA_JOB,
          ctaType: CTA_TYPE.RETURN_CAREER_PAGE,
          label: t.JOB_SUBMISSION_SUCCESS_CTA,
        }}
      />
    </section>
  );
}

ConfirmationForm.propTypes = {
  lang: PropTypes.oneOf(['EN', 'DE']),
};
export default ConfirmationForm;
