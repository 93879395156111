export const HEADLINES = {
  WORK_TIME: 'Effective work time',
};

export const LABELS = {
  COST: 'Cost',
  SALARY_PER_ANNUM: 'Salary p.a.',
  ADDON_COST_PERCENT: 'Addon costs',
  COST_OF_REPLACEMENT: 'Costs of replacement',
  INFRASTRUCTURE_COSTS: 'Infrastructure costs',
  YEARS_UNTIL_REPLACEMENT: 'Length of employment',
  MONTHS_LOST_IN_SEARCH: 'Loss in search',
  SQM_PER_EMPLOYEE_OFFICE_SPACE: 'Office space per employee',
  EUR_PER_SQM_RENT_PER_MONTH: 'Rent per month',
  COST_PERSONAL_EQUIPMENT_PER_ANNUM: 'Costs of personal equipment p.a.',
  IDLE_TIME_PERCENT: 'Idle time',
  ABSENCE: 'Absence',
  BASE_WORKDAYS: 'Base work days',
  DAYS_VACATION: 'Vacation',
  DAYS_SICK_LEAVE: 'Sick leave',
  INTERNAL_EVENTS: 'Internal events',
  EXTERNAL_EVENTS: 'External events',
  HOURS_PER_WEEK_OVERHEAD: 'Overhead tasks',
  HOURS_PER_WEEK_TEAM_MANAGEMENT: 'Team management',
  HOURS_PER_WEEK_R_AND_D: 'Research & development',
  OVERHEAD_COSTS: 'Overhead per # of employees',
};

export const CAPTIONS = {
  COST_PER_HOUR: 'per hour',
  COST_PER_EFFECTIVE_HOUR: 'per effective hour',
  MONTHS_LOST_IN_SEARCH: 'Efficiency loss, brain drain, etc.',
  COST_PERSONAL_EQUIPMENT_PER_ANNUM: 'Computer, phone, etc.',
  INTERNAL_EVENTS:
    'Weekly meetings, open space formats, annual company meetings, etc.',
  EXTERNAL_EVENTS: 'Conferences, trade fairs, trainings, etc.',
  HOURS_PER_WEEK_OVERHEAD:
    'StandUp, AllHands, self organization, expenses, etc.',
  HOURS_PER_WEEK_TEAM_MANAGEMENT: 'Recruiting, feedback, etc.',
  HOURS_PER_WEEK_R_AND_D: 'Due diligence, blogs, etc.',
  OVERHEAD_COSTS: 'Finance, Reception, HR, leadership, etc.',
};
