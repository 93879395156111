import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './input.module.scss';

const Input = React.forwardRef(function Input(
  {
    checked,
    onChange,
    value,
    label,
    name,
    className,
    type = 'checkbox',
    ...rest
  },
  ref
) {
  return (
    <label className={classNames(styles.label, className)}>
      <input
        type={type}
        id={name}
        name={name}
        checked={checked}
        onChange={onChange}
        className={styles.input}
        value={value}
        ref={ref}
        {...rest}
      />

      <div className={styles.box} />

      <div className={styles.text}>{label}</div>
    </label>
  );
});

Input.propTypes = {
  type: PropTypes.oneOf(['checkbox', 'radio']).isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default Input;
