import ClaimSlider from './content/claim-slider/claim-slider';
import CostOfWork from './content/cost-of-work/cost-of-work';
import EmployeeList from './content/employee-list/employee-list';
import FaqList from './content/faq-list/faq-list';
import Job from './content/job/job';
import JobList from './content/job-list/job-list';
import KnowledgeSection from './content/knowledge-section/knowledge-section';
import KpiSlider from './content/kpi-slider/kpi-slider';
import MediaAssetList from './content/media-asset-list/media-asset-list';
import Partner from './content/partner/partner';
import PictureSlider from './content/picture-slider/picture-slider';
import PressKitList from './content/press-kit-list/press-kit-list';
import PressReleaseOverview from './content/press-release-overview/press-release-overview';
import PressReleaseSection from './content/press-release-section/press-release-section';
import SectionIndicator from './global/section-indicator/section-indicator';
import TeamMemberSlider from './content/team-member-slider/team-member-slider';
import Teaser from './content/teaser/teaser';
import ValuesSection from './content/values-section/values-section';
import VentureSection from './content/venture-section/venture-section';
import VideoEmbed from './content/video-embed/video-embed';
import Wysiwyg from './content/wysiwyg/wysiwyg';
import ContentItemDetail from './content/content-item-detail/content-item-detail';
import ImageLinkList from './content/image-link-list/image-link-list';
import PakconLanding from './content/pakcon-landing/pakcon-landing';
import CookieSettings from './content/cookie-settings/cookie-settings';
import ContentItemCustomizableList from './content/customizable-list/customizable-list';
import SuccessStoriesSlider from './content/success-stories-slider/success-stories-slider';

export { default as Layout } from './layout/layout';

import { DOWNLOAD_TYPES } from '@/shared/constants.mjs';

export function mapSectionToComponent({ contentType, type }) {
  switch (contentType) {
    case 'teaser':
      return Teaser;

    case 'job':
      return Job;

    case 'wysiwyg':
      return Wysiwyg;

    case 'claimSlider':
      return ClaimSlider;

    case 'customSection':
      switch (type) {
        case 'Values':
          return ValuesSection;
        case 'Cost of work':
          return CostOfWork;
        case 'KPI Slider':
          return KpiSlider;
        case 'Cookie settings':
          return CookieSettings;
      }
      break;

    case 'customizableList':
      switch (type) {
        case 'Content Item':
          return ContentItemCustomizableList;
      }
      break;

    case 'list':
      switch (type) {
        case 'Ventures':
          return VentureSection;
        case 'Employees':
          return EmployeeList;
        case 'Knowledge':
          return KnowledgeSection;
        case 'Press Releases':
          return PressReleaseSection;
        case 'Press Releases Overview':
          return PressReleaseOverview;
        case 'Jobs':
          return JobList;
        case 'How to approach us FAQ':
        case 'What is your investment criteria FAQ':
        case 'Process FAQ':
        case 'Work with Project A FAQ':
        case 'Operational VC FAQ':
        case 'Investment FAQ':
        case 'About Us FAQ':
        case 'Studio FAQ':
          return FaqList;
        case DOWNLOAD_TYPES.MEDIA_ASSETS:
          return MediaAssetList;
        case DOWNLOAD_TYPES.PRESS_KIT_PARTNERS:
          return PressKitList;
      }
      break;

    case 'itemSlider':
      switch (type) {
        case 'Team Members':
          return TeamMemberSlider;
        case 'Pictures':
          return PictureSlider;
        case 'Success Stories':
          return SuccessStoriesSlider;
      }
      break;

    case 'teamMember':
      switch (type) {
        case 'Management':
        case 'Investment':
          return Partner;
      }
      break;
    case 'sectionIndicator':
      return SectionIndicator;

    case 'knowledge':
      return ContentItemDetail;

    case 'imageLinkList':
      return ImageLinkList;

    case 'pakConLanding':
      return PakconLanding;

    case 'VideoEmbed':
      return VideoEmbed;
  }
}
