import React from 'react';
import { useFormContext } from 'react-hook-form';

import { GreenhouseQuestionShape } from '../../../../../shared/shapes';

function HiddenInput({ question }) {
  const { name, value } = question;
  const { register } = useFormContext();

  return <input type="hidden" name={name} value={value} {...register(name)} />;
}

HiddenInput.propTypes = {
  question: GreenhouseQuestionShape,
};

export default HiddenInput;
