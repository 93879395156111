import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { VentureShape } from '../../../../../shared/shapes';
import styles from './venture-tile.module.scss';

const STATUS = {
  EXITED: 'Exited',
  ACTIVE: 'Active',
  UNICORN: 'Unicorn',
};

function VentureTile({ venture, selected }) {
  const isExited = venture.status === STATUS.EXITED;
  const isUnicorn = venture.status === STATUS.UNICORN;

  {
    return (
      <div
        className={classNames(styles.tile, {
          [styles.selected]: selected,
          [styles.exited]: isExited,
          [styles.unicorn]: isUnicorn,
        })}
      >
        <div className={styles.content}>
          <div className={styles.highlightedContent}>
            <div>
              <h2 className={styles.headline}>{venture.headline}</h2>
              <p className={styles.subheadline}>{venture.subheadline}</p>
            </div>
          </div>
        </div>

        <p className={styles.status}>{venture.status}</p>

        {venture.logo && (
          <picture>
            <source srcSet={venture.logo.file && venture.logo.file.url} />
            <img
              src={venture.logo.file && venture.logo.file.url}
              alt={venture.logo.title}
              className={styles.logo}
            />
          </picture>
        )}
      </div>
    );
  }
}

VentureTile.propTypes = {
  venture: VentureShape,
  selected: PropTypes.bool,
};

export default VentureTile;
