import React from 'react';
import classNames from 'classnames';
import { useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';

import { GreenhouseQuestionShape } from '../../../../../shared/shapes';
import {
  useTranslatedContent,
  APPLICATION_FORM,
} from '../../../../../shared/content';

import Label from '../label/label';
import Error from '../error/error';

import styles from './file-input.module.scss';

const SUPPORTED_EXTENSIONS = ['pdf', 'doc', 'docx', 'zip'];

function FileInput({ question, lang }) {
  const validateFileInput = value => {
    if (!value.length) {
      return true;
    }

    const extension = value[0].name.split('.').pop();
    return (
      SUPPORTED_EXTENSIONS.includes(extension) ||
      `${t.VALIDATION_WRONG_FORMAT} ${SUPPORTED_EXTENSIONS.join(', ')}`
    );
  };

  const { required, description, label, name } = question;

  const t = useTranslatedContent(APPLICATION_FORM, lang);

  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext();
  const error = errors[name];

  const files = watch(name, []);

  return (
    <div className={styles.fileInput}>
      <label className={classNames(styles.label, { [styles.hasError]: error })}>
        <div className={styles.labelText}>
          <Label text={description || label} required={required} lang={lang} />
          {files.length > 0 && (
            <div className={styles.fileName}>{files.item(0).name}</div>
          )}
        </div>

        <input
          className={styles.input}
          type="file"
          {...register(name, {
            required: required && t.VALIDATION_REQUIRED,
            validate: validateFileInput,
          })}
          accept={SUPPORTED_EXTENSIONS.map(extension =>
            '.'.concat(extension)
          ).join()}
        />

        <div className={styles.labelHelp}>{t.FILE_INPUT_CTA}</div>
      </label>

      {error && <Error text={error.message} />}
    </div>
  );
}

FileInput.propTypes = {
  question: GreenhouseQuestionShape,
  lang: PropTypes.oneOf(['EN', 'DE']),
};

export default FileInput;
