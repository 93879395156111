import classNames from 'classnames';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import PropTypes from 'prop-types';
import React, { Fragment, useState } from 'react';
import { RICH_TEXT_OPTIONS } from '../../../../../shared/utils/rich-text';
import { TeamMemberShape } from '../../../../../shared/shapes';
import Tile from '../tile/tile';

import QuoteBegin from './quotes-begin.svg';
import QuoteEnd from './quotes-end.svg';
import styles from './employee-tiles-with-image.module.scss';

function EmployeeTilesWithImage({ items, areQuotesVisible }) {
  const [highlightItemIndex, setHighlightItemIndex] = useState(null);

  return (
    <section className={styles.container}>
      {items.map((item, index) => {
        return (
          <Fragment key={index}>
            {item.contentType !== 'teamMember' && areQuotesVisible && (
              <div
                className={classNames(
                  styles.quote,
                  { [styles.quotesLeft]: index % 2 == 0 },
                  { [styles.quotesRight]: index % 2 !== 0 }
                )}
              >
                <div className={styles.quoteBegin}>
                  <QuoteBegin />
                </div>
                {documentToReactComponents(item, RICH_TEXT_OPTIONS)}
                <div className={styles.quoteEnd}>
                  <QuoteEnd />
                </div>
              </div>
            )}
            {item.contentType === 'teamMember' && (
              <Tile
                item={item}
                index={index}
                highlightItemIndex={highlightItemIndex}
                setHighlightItemIndex={setHighlightItemIndex}
                className={styles.tile}
              />
            )}
          </Fragment>
        );
      })}
    </section>
  );
}

EmployeeTilesWithImage.propTypes = {
  items: PropTypes.arrayOf(TeamMemberShape),
  areQuotesVisible: PropTypes.bool,
};

export default EmployeeTilesWithImage;
