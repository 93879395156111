import React from 'react';
import propTypes from 'prop-types';
import { getTransformationMediaUrl } from '../../../shared/utils/url/url';

import { MediaShape } from '../../../shared/shapes';
import { EVENTS } from '../../../shared/hooks/use-tracking/data-layer-variables';
import Slider, { MODIFIERS } from '../../../components/global/slider/slider';

import styles from './picture-slider.module.scss';

const PICTURE_CONFIG = {
  fm: 'jpg',
  bg: 'rgb:000000',
  w: 1140,
};

function PictureSlider({ pictureItems }) {
  return (
    <section className={styles.pictureSlider}>
      <Slider
        modifiers={[MODIFIERS.FULL_WIDTH]}
        trackingEventType={EVENTS.SLIDER_NAVIGATION}
      >
        {pictureItems.map(picture => (
          <picture key={picture.id}>
            <source
              srcSet={getTransformationMediaUrl(picture, PICTURE_CONFIG)}
            />
            <img
              src={getTransformationMediaUrl(picture, PICTURE_CONFIG)}
              alt={picture.description}
            />
          </picture>
        ))}
      </Slider>
    </section>
  );
}

PictureSlider.propTypes = {
  pictureItems: propTypes.arrayOf(MediaShape),
};

export default PictureSlider;
