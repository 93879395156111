import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';

import { GreenhouseQuestionShape } from '../../../../../shared/shapes';
import { stripTags } from '../../../../../shared/utils/string';
import { useTranslatedContent, JOB_POST } from '../../../../../shared/content';
import Input from '../../../../../components/global/form/input/input';

import styles from './checkbox-input.module.scss';

function CheckboxInput({ question, lang }) {
  const t = useTranslatedContent(JOB_POST, lang);
  const { required, name, value: questionValue, description, label } = question;
  const [value, setValue] = useState(String(questionValue));

  const toggleValue = () => setValue(value === '1' ? '0' : '1');

  const { register, watch } = useFormContext();

  const currentValue = watch(name);

  return (
    <div className={styles.checkboxInput}>
      <Input
        type="checkbox"
        checked={currentValue === '1'}
        onChange={toggleValue}
        value={1}
        label={`${stripTags(description || label)} ${required ? '*' : ''}`}
        required={required}
        {...register(name, { required: required && t.VALIDATION_REQUIRED })}
      />
    </div>
  );
}

CheckboxInput.propTypes = {
  question: GreenhouseQuestionShape,
  lang: PropTypes.oneOf(['EN', 'DE']),
};

export default CheckboxInput;
