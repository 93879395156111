import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useFormContext } from 'react-hook-form';

import Label from '../label/label';
import Error from '../error/error';

import styles from './text-input.module.scss';

import { useTranslatedContent, APPLICATION_FORM } from '@/shared/content';
import { GreenhouseQuestionShape } from '@/shared/shapes';

function TextInput({ question, lang }) {
  const t = useTranslatedContent(APPLICATION_FORM, lang);

  const {
    required,
    disabled = false,
    description,
    label,
    name,
    value,
    type = 'text',
  } = question;

  const {
    register,
    formState: { errors },
  } = useFormContext();
  const error = errors[name];

  return (
    <div
      className={classNames(styles.textInput, { [styles.disabled]: disabled })}
    >
      <label className={styles.label}>
        <Label text={description || label} required={required} lang={lang} />

        <input
          className={classNames(styles.input, { [styles.hasError]: error })}
          value={value}
          type={type}
          placeholder={t.TEXT_INPUT_CTA}
          {...register(name, {
            required: required && t.VALIDATION_REQUIRED,
            minLength: required && {
              value: 2,
              message: t.VALIDATION_MIN_LENGTH,
            },
          })}
          disabled={disabled}
        />
      </label>

      {error && <Error text={error.message} />}
    </div>
  );
}

TextInput.propTypes = {
  question: GreenhouseQuestionShape,
  lang: PropTypes.oneOf(['EN', 'DE']),
};

export default TextInput;
