import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useFilters } from '../../../shared/hooks/use-filters/use-filters';
import { CTA_TYPE } from '../../../shared/hooks/use-tracking/data-layer-variables';
import { FilterGroupShape, TeamMemberShape } from '../../../shared/shapes';
import ExpandingFilters from '../../../components/global/expanding-filters/expanding-filters';
import styles from './employee-list.module.scss';
import EmployeeTilesSimple from './employee-tiles/employee-tiles-simple/employee-tiles-simple';
import EmployeeTilesWithImage from './employee-tiles/employee-tiles-with-image/employee-tiles-with-image';
import {
  extractQuotesFromTeamMember,
  groupByPicture,
  injectQuotesInEmployeeGroup,
  isAnyFilterOptionActive,
} from './helper';

function EmployeeList({ items: initialItems, initialFilterGroups }) {
  const [employeeList, setEmployeeList] = useState(initialItems);
  const [showQuotes, setShowQuotes] = useState(true);

  const quotesRef = useRef(extractQuotesFromTeamMember(initialItems));

  const [filterGroups, items, actions] = useFilters(
    initialFilterGroups,
    initialItems
  );

  useEffect(() => {
    setEmployeeList(items);
  }, [items]);

  useEffect(() => {
    setShowQuotes(!isAnyFilterOptionActive(filterGroups));
  }, [filterGroups]);

  const employeesGroupedByImage = groupByPicture(employeeList);
  const itemsWithInjectedQuotes = injectQuotesInEmployeeGroup(
    employeesGroupedByImage.withImage,
    quotesRef.current
  );

  return (
    <section className={styles.section}>
      <ExpandingFilters
        filterGroups={filterGroups}
        actions={actions}
        trackingCtaType={CTA_TYPE.FILTERS_TEAM}
      />
      <EmployeeTilesWithImage
        items={itemsWithInjectedQuotes}
        areQuotesVisible={showQuotes}
      />
      <EmployeeTilesSimple items={employeesGroupedByImage.withoutImage} />
    </section>
  );
}

EmployeeList.propTypes = {
  items: PropTypes.arrayOf(TeamMemberShape),
  initialFilterGroups: PropTypes.arrayOf(FilterGroupShape),
};

export default EmployeeList;
