import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { getHashParams, setHashParams } from '../../../shared/utils/url/url';
import { HEADLINES, LABELS, CAPTIONS } from './content';
import { DEFAULT_VALUES, getCalculatedValues } from './calculation';
import UnitValue from './unit-value/unit-value';
import UnitValueInput from './unit-value-input/unit-value-input';

import styles from './cost-of-work.module.scss';

function CostOfWork({ content: { initialValues = {} } }) {
  const [highlight, setHighlight] = useState();

  const [input, setInput] = useState({ ...DEFAULT_VALUES, ...initialValues });
  const handleInputChangeFor = name => value => {
    setInput({ ...input, [name]: value });
  };

  useEffect(() => {
    const params = Object.entries(getHashParams()).reduce(
      (object, [key, value]) => {
        object[key] = parseFloat(value);
        return object;
      },
      {}
    );
    setInput({ ...DEFAULT_VALUES, ...initialValues, ...params });
  }, [initialValues]);

  useEffect(() => {
    setHashParams(input);
  }, [input]);

  const calculated = getCalculatedValues(input);

  return (
    <div className={styles.costOfWork}>
      <div className={styles.inner}>
        <div className={classNames(styles.row, styles.rowCenter)}>
          <div className={styles.salaryItem}>
            <div className={styles.salaryItemLabel}>
              {LABELS.SALARY_PER_ANNUM}
            </div>
            <div>
              <UnitValueInput
                modifiers={['salary']}
                value={input.salaryPerAnnum}
                unit="€"
                onChange={handleInputChangeFor('salaryPerAnnum')}
              />
            </div>
          </div>
          <div className={styles.centerItem}>
            <h2 className={styles.centerItemHeadline}>
              <div className={styles.centerItemTitle}>{LABELS.COST}</div>
              <div className={styles.centerItemCaption}>
                {CAPTIONS.COST_PER_HOUR}
              </div>
            </h2>
            <div>
              <UnitValue
                modifiers={['large']}
                value={calculated.costPerHour}
                unit="€"
              />
            </div>
          </div>
          <span>vs</span>
          <div className={styles.centerItem}>
            <h2 className={styles.centerItemHeadline}>
              <div className={styles.centerItemTitle}>{LABELS.COST}</div>
              <div className={styles.centerItemCaption}>
                {CAPTIONS.COST_PER_EFFECTIVE_HOUR}
              </div>
            </h2>
            <div>
              <UnitValue
                modifiers={['large']}
                value={calculated.costPerEffectiveHour}
                unit="€"
              />
            </div>
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.rowHeadline}>
            <h2 className={styles.rowHeadlineText}>{LABELS.COST}</h2>
            <div className={styles.rowHeadlineSide}>
              <UnitValue
                modifiers={['headline']}
                value={calculated.costFactor}
                unit="x"
              />
            </div>
          </div>
          <div className={styles.rowItem}>
            <table className={classNames(styles.table, styles.large)}>
              <tbody
                onMouseEnter={() => setHighlight('ADDON_COST_PERCENT')}
                onMouseLeave={() => setHighlight(null)}
                className={classNames({
                  [styles.highlight]: highlight === 'ADDON_COST_PERCENT',
                })}
              >
                <tr>
                  <th className={styles.cell}>{LABELS.ADDON_COST_PERCENT}</th>
                  <td className={styles.cell}>
                    <UnitValue value={calculated.addonCostPercent} unit="%" />
                  </td>
                </tr>
              </tbody>
              <tbody
                onMouseEnter={() => setHighlight('COST_OF_REPLACEMENT')}
                onMouseLeave={() => setHighlight(null)}
                className={classNames({
                  [styles.highlight]: highlight === 'COST_OF_REPLACEMENT',
                })}
              >
                <tr>
                  <th className={styles.cell}>{LABELS.COST_OF_REPLACEMENT}</th>
                  <td className={styles.cell}>
                    <UnitValue
                      value={calculated.costOfReplacementPercent}
                      unit="%"
                    />
                  </td>
                </tr>
              </tbody>
              <tbody
                onMouseEnter={() => setHighlight('OVERHEAD_COSTS')}
                onMouseLeave={() => setHighlight(null)}
                className={classNames({
                  [styles.highlight]: highlight === 'OVERHEAD_COSTS',
                })}
              >
                <tr>
                  <th className={styles.cell}>Overhead costs</th>
                  <td className={styles.cell}>
                    <UnitValue
                      value={calculated.overheadCostsPercent}
                      unit="%"
                    />
                  </td>
                </tr>
              </tbody>
              <tbody
                onMouseEnter={() => setHighlight('INFRASTRUCTURE_COSTS')}
                onMouseLeave={() => setHighlight(null)}
                className={classNames({
                  [styles.highlight]: highlight === 'INFRASTRUCTURE_COSTS',
                })}
              >
                <tr>
                  <th className={styles.cell}>{LABELS.INFRASTRUCTURE_COSTS}</th>
                  <td className={styles.cell}>
                    <UnitValue
                      value={calculated.infrastructureCostsPercent}
                      unit="%"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className={styles.rowItem}>
            <table className={styles.table}>
              <tbody
                onMouseEnter={() => setHighlight('ADDON_COST_PERCENT')}
                onMouseLeave={() => setHighlight(null)}
                className={classNames(styles.subsection, {
                  [styles.highlight]: highlight === 'ADDON_COST_PERCENT',
                })}
              >
                <tr>
                  <th className={styles.cell}>Tax &amp; social security</th>
                  <td className={styles.cell}>
                    <UnitValueInput
                      value={input.addonCostPercent}
                      unit="%"
                      onChange={handleInputChangeFor('addonCostPercent')}
                    />
                  </td>
                </tr>
              </tbody>
              <tbody
                onMouseEnter={() => setHighlight('COST_OF_REPLACEMENT')}
                onMouseLeave={() => setHighlight(null)}
                className={classNames(styles.subsection, {
                  [styles.highlight]: highlight === 'COST_OF_REPLACEMENT',
                })}
              >
                <tr>
                  <th className={styles.cell}>
                    {LABELS.YEARS_UNTIL_REPLACEMENT}
                  </th>
                  <td className={styles.cell}>
                    <UnitValueInput
                      value={input.yearsUntilReplacement}
                      unit="yrs"
                      onChange={handleInputChangeFor('yearsUntilReplacement')}
                    />
                  </td>
                </tr>
                <tr>
                  <th className={styles.cell}>
                    {LABELS.MONTHS_LOST_IN_SEARCH}
                    <span className={styles.caption}>
                      {CAPTIONS.MONTHS_LOST_IN_SEARCH}
                    </span>
                  </th>
                  <td className={styles.cell}>
                    <UnitValueInput
                      value={input.monthsLostInSearch}
                      unit="mos"
                      onChange={handleInputChangeFor('monthsLostInSearch')}
                    />
                  </td>
                </tr>
              </tbody>
              <tbody
                onMouseEnter={() => setHighlight('OVERHEAD_COSTS')}
                onMouseLeave={() => setHighlight(null)}
                className={classNames(styles.subsection, {
                  [styles.highlight]: highlight === 'OVERHEAD_COSTS',
                })}
              >
                <tr>
                  <th className={styles.cell}>
                    {LABELS.OVERHEAD_COSTS}
                    <span className={styles.caption}>
                      {CAPTIONS.OVERHEAD_COSTS}
                    </span>
                  </th>
                  <td className={styles.cell}>
                    <UnitValueInput
                      value={input.overheadCosts}
                      onChange={handleInputChangeFor('overheadCosts')}
                    />
                  </td>
                </tr>
              </tbody>
              <tbody
                onMouseEnter={() => setHighlight('INFRASTRUCTURE_COSTS')}
                onMouseLeave={() => setHighlight(null)}
                className={classNames(styles.subsection, {
                  [styles.highlight]: highlight === 'INFRASTRUCTURE_COSTS',
                })}
              >
                <tr>
                  <th className={styles.cell}>
                    {LABELS.SQM_PER_EMPLOYEE_OFFICE_SPACE}
                  </th>
                  <td className={styles.cell}>
                    <UnitValueInput
                      value={input.sqmPerEmployeeOfficeSpace}
                      unit="m²"
                      onChange={handleInputChangeFor(
                        'sqmPerEmployeeOfficeSpace'
                      )}
                    />
                  </td>
                </tr>
                <tr>
                  <th className={styles.cell}>
                    {LABELS.EUR_PER_SQM_RENT_PER_MONTH}
                  </th>
                  <td className={styles.cell}>
                    <UnitValueInput
                      value={input.eurPerSqmRentPerMonth}
                      unit="€/m²"
                      onChange={handleInputChangeFor('eurPerSqmRentPerMonth')}
                    />
                  </td>
                </tr>
                <tr>
                  <th className={styles.cell}>
                    {LABELS.COST_PERSONAL_EQUIPMENT_PER_ANNUM}
                    <span className={styles.caption}>
                      {CAPTIONS.COST_PERSONAL_EQUIPMENT_PER_ANNUM}
                    </span>
                  </th>
                  <td className={styles.cell}>
                    <UnitValueInput
                      value={input.costPersonalEquipmentPerAnnum}
                      unit="€"
                      onChange={handleInputChangeFor(
                        'costPersonalEquipmentPerAnnum'
                      )}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.rowHeadline}>
            <h2 className={styles.rowHeadlineText}>{HEADLINES.WORK_TIME}</h2>
            <div className={styles.rowHeadlineSide}>
              <UnitValue
                modifiers={['headline']}
                value={calculated.workFactor}
                unit="x"
              />
            </div>
          </div>
          <div className={styles.rowItem}>
            <table className={classNames(styles.table, styles.large)}>
              <tbody>
                <tr>
                  <th className={styles.cell}>{LABELS.IDLE_TIME_PERCENT}</th>
                  <td className={styles.cell}>
                    <UnitValueInput
                      value={input.idleTimePercent}
                      unit="%"
                      onChange={handleInputChangeFor('idleTimePercent')}
                    />
                  </td>
                </tr>
              </tbody>
              <tbody>
                <tr
                  onMouseEnter={() => setHighlight('ABSENCE')}
                  onMouseLeave={() => setHighlight(null)}
                  className={classNames(styles.subsection, {
                    [styles.highlight]: highlight === 'ABSENCE',
                  })}
                >
                  <th className={styles.cell}>{LABELS.ABSENCE}</th>
                  <td className={styles.cell}>
                    <UnitValue
                      value={calculated.totalAbsensePercent}
                      unit="%"
                    />
                  </td>
                </tr>
              </tbody>
              <tbody
                onMouseEnter={() => setHighlight('HOURS_PER_WEEK_OVERHEAD')}
                onMouseLeave={() => setHighlight(null)}
                className={classNames(styles.subsection, {
                  [styles.highlight]: highlight === 'HOURS_PER_WEEK_OVERHEAD',
                })}
              >
                <tr>
                  <th className={styles.cell}>
                    {LABELS.HOURS_PER_WEEK_OVERHEAD}
                  </th>
                  <td className={styles.cell}>
                    <UnitValue
                      value={calculated.overheadTasksPercent}
                      unit="%"
                    />
                  </td>
                </tr>
              </tbody>
              <tbody
                onMouseEnter={() =>
                  setHighlight('HOURS_PER_WEEK_TEAM_MANAGEMENT')
                }
                onMouseLeave={() => setHighlight(null)}
                className={classNames(styles.subsection, {
                  [styles.highlight]:
                    highlight === 'HOURS_PER_WEEK_TEAM_MANAGEMENT',
                })}
              >
                <tr>
                  <th className={styles.cell}>
                    {LABELS.HOURS_PER_WEEK_TEAM_MANAGEMENT}
                  </th>
                  <td className={styles.cell}>
                    <UnitValue
                      value={calculated.teamManagementPercent}
                      unit="%"
                    />
                  </td>
                </tr>
              </tbody>
              <tbody
                onMouseEnter={() => setHighlight('HOURS_PER_WEEK_R_AND_D')}
                onMouseLeave={() => setHighlight(null)}
                className={classNames(styles.subsection, {
                  [styles.highlight]: highlight === 'HOURS_PER_WEEK_R_AND_D',
                })}
              >
                <tr>
                  <th className={styles.cell}>
                    {LABELS.HOURS_PER_WEEK_R_AND_D}
                  </th>
                  <td className={styles.cell}>
                    <UnitValue value={calculated.rAndDPercent} unit="%" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className={styles.rowItem}>
            <table className={styles.table}>
              <tbody>
                <tr>
                  <th className={styles.cell}>{LABELS.baseWorkdays}</th>
                  <td className={styles.cell}>
                    <UnitValue value={calculated.baseWorkdays} unit="days" />
                  </td>
                </tr>
              </tbody>
              <tbody
                onMouseEnter={() => setHighlight('ABSENCE')}
                onMouseLeave={() => setHighlight(null)}
                className={classNames(styles.subsection, {
                  [styles.highlight]: highlight === 'ABSENCE',
                })}
              >
                <tr>
                  <th className={styles.cell}>{LABELS.DAYS_VACATION}</th>
                  <td className={styles.cell}>
                    <UnitValueInput
                      value={input.daysVacation}
                      unit="days"
                      onChange={handleInputChangeFor('daysVacation')}
                    />
                  </td>
                </tr>
                <tr>
                  <th className={styles.cell}>{LABELS.DAYS_SICK_LEAVE}</th>
                  <td className={styles.cell}>
                    <UnitValueInput
                      value={input.daysSickLeave}
                      unit="days"
                      onChange={handleInputChangeFor('daysSickLeave')}
                    />
                  </td>
                </tr>
                <tr>
                  <th className={styles.cell}>
                    {LABELS.INTERNAL_EVENTS}
                    <span className={styles.caption}>
                      {CAPTIONS.INTERNAL_EVENTS}
                    </span>
                  </th>
                  <td className={styles.cell}>
                    <UnitValueInput
                      value={input.daysPerAnnumInternalEvents}
                      unit="days"
                      onChange={handleInputChangeFor(
                        'daysPerAnnumInternalEvents'
                      )}
                    />
                  </td>
                </tr>
                <tr>
                  <th className={styles.cell}>
                    {LABELS.EXTERNAL_EVENTS}
                    <span className={styles.caption}>
                      {CAPTIONS.EXTERNAL_EVENTS}
                    </span>
                  </th>
                  <td className={styles.cell}>
                    <UnitValueInput
                      value={input.daysPerAnnumExternalEvents}
                      unit="days"
                      onChange={handleInputChangeFor(
                        'daysPerAnnumExternalEvents'
                      )}
                    />
                  </td>
                </tr>
              </tbody>
              <tbody
                onMouseEnter={() => setHighlight('HOURS_PER_WEEK_OVERHEAD')}
                onMouseLeave={() => setHighlight(null)}
                className={classNames(styles.subsection, {
                  [styles.highlight]: highlight === 'HOURS_PER_WEEK_OVERHEAD',
                })}
              >
                <tr>
                  <th className={styles.cell}>
                    {LABELS.HOURS_PER_WEEK_OVERHEAD}
                    <span className={styles.caption}>
                      {CAPTIONS.HOURS_PER_WEEK_OVERHEAD}
                    </span>
                  </th>
                  <td className={styles.cell}>
                    <UnitValueInput
                      value={input.hoursPerWeekOverhead}
                      unit="hrs/wk"
                      onChange={handleInputChangeFor('hoursPerWeekOverhead')}
                    />
                  </td>
                </tr>
              </tbody>
              <tbody
                onMouseEnter={() =>
                  setHighlight('HOURS_PER_WEEK_TEAM_MANAGEMENT')
                }
                onMouseLeave={() => setHighlight(null)}
                className={classNames(styles.subsection, {
                  [styles.highlight]:
                    highlight === 'HOURS_PER_WEEK_TEAM_MANAGEMENT',
                })}
              >
                <tr>
                  <th className={styles.cell}>
                    {LABELS.HOURS_PER_WEEK_TEAM_MANAGEMENT}
                    <span className={styles.caption}>
                      {CAPTIONS.HOURS_PER_WEEK_TEAM_MANAGEMENT}
                    </span>
                  </th>
                  <td className={styles.cell}>
                    <UnitValueInput
                      value={input.hoursPerWeekTeamManagement}
                      unit="hrs/wk"
                      onChange={handleInputChangeFor(
                        'hoursPerWeekTeamManagement'
                      )}
                    />
                  </td>
                </tr>
              </tbody>
              <tbody
                onMouseEnter={() => setHighlight('HOURS_PER_WEEK_R_AND_D')}
                onMouseLeave={() => setHighlight(null)}
                className={classNames(styles.subsection, {
                  [styles.highlight]: highlight === 'HOURS_PER_WEEK_R_AND_D',
                })}
              >
                <tr>
                  <th className={styles.cell}>
                    {LABELS.HOURS_PER_WEEK_R_AND_D}
                    <span className={styles.caption}>
                      {CAPTIONS.HOURS_PER_WEEK_R_AND_D}
                    </span>
                  </th>
                  <td className={styles.cell}>
                    <UnitValueInput
                      value={input.hoursPerWeekRAndD}
                      unit="hrs/wk"
                      onChange={handleInputChangeFor('hoursPerWeekRAndD')}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

CostOfWork.propTypes = {
  content: PropTypes.shape({
    initialValues: PropTypes.object,
  }),
};

export default CostOfWork;
