import React from 'react';
import PropTypes from 'prop-types';
import { stripTags } from '../../../../../shared/utils/string';
import { APPLICATION_FORM } from '../../../../../shared/content';

// workaround: Greenhouse API does not return localized content
const GERMAN_TEXT_MAPPING = {
  'First Name': APPLICATION_FORM.LABEL_FIRST_NAME.DE,
  'Last Name': APPLICATION_FORM.LABEL_LAST_NAME.DE,
  Email: APPLICATION_FORM.LABEL_EMAIL.DE,
  Phone: APPLICATION_FORM.LABEL_PHONE.DE,
  Birthday: APPLICATION_FORM.LABEL_BIRTHDAY.DE,
  'Available from': APPLICATION_FORM.LABEL_AVAILABLE_FROM.DE,
  'Cover Letter': APPLICATION_FORM.LABEL_COVER_LETTER.DE,
  Resume: APPLICATION_FORM.LABEL_CV.DE,
};

function Label({ text, required = false, lang }) {
  text =
    lang === 'DE' && GERMAN_TEXT_MAPPING[text]
      ? GERMAN_TEXT_MAPPING[text]
      : text;

  return (
    <>
      {stripTags(text)} {required ? '*' : ''}
    </>
  );
}

Label.propTypes = {
  text: PropTypes.string.isRequired,
  required: PropTypes.bool,
  lang: PropTypes.oneOf(['EN', 'DE']),
};

export default Label;
