import React from 'react';
import { Layout, mapSectionToComponent } from '../';
import { PageShape } from '@/shared/shapes';

function Page({ page }) {
  const sections = page.content
    .map(contentSection => ({
      Component: mapSectionToComponent(contentSection),
      props: contentSection,
    }))
    .filter(({ Component }) => Component);

  return (
    <div>
      <Layout>
        {sections.map(({ Component, props: properties }) => (
          <Component key={properties.id} {...properties} />
        ))}
      </Layout>
    </div>
  );
}

Page.propTypes = {
  page: PageShape,
};

export default Page;
