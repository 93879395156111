import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { JobShape } from '../../../../shared/shapes';
import {
  EVENTS,
  CTA_TYPE,
} from '../../../../shared/hooks/use-tracking/data-layer-variables';
import Arrow from '../../../../shared/icons/long-arrow.svg';

import Link from '../../../../components/global/link/link';

import styles from './job-table-rows.module.scss';

function JobTableRows({ items }) {
  return (
    <tbody>
      {items.map(({ greenhouseData }) => {
        const {
          listTitle,
          organization,
          location,
          jobPostId,
          seniorityLevel,
          employmentType,
          url,
        } = greenhouseData;

        return (
          <tr key={jobPostId} className={styles.row}>
            <td className={classNames(styles.cell, styles.title)}>
              <Link
                href={url}
                trackingData={{
                  event: EVENTS.CLICK_CTA_JOB,
                  ctaType: CTA_TYPE.JOB_NAME,
                  label: listTitle,
                }}
                isExternalUrl={true}
              >
                <a
                  className={styles.link}
                  title={`Details for ${listTitle}`}
                  dangerouslySetInnerHTML={{
                    __html: listTitle.replace('/', '/<wbr>'),
                  }}
                />
              </Link>
            </td>

            <td
              className={classNames(styles.cell, styles.seniorityLevel)}
              dangerouslySetInnerHTML={{
                __html: seniorityLevel.replace('/', '/<wbr>'),
              }}
            />

            <td className={classNames(styles.cell, styles.employmentType)}>
              {employmentType}
            </td>

            <td className={styles.lineBreak} />

            <td className={classNames(styles.cell, styles.organization)}>
              {organization}
            </td>

            <td className={classNames(styles.cell, styles.location)}>
              {location}
            </td>

            <td className={classNames(styles.cell, styles.arrowCell)}>
              <Link
                href={url}
                trackingData={{
                  event: EVENTS.CLICK_CTA_JOB,
                  ctaType: CTA_TYPE.JOB_ARROW,
                  label: listTitle,
                }}
                isExternalUrl={true}
              >
                <a
                  className={classNames(styles.link, styles.arrow)}
                  title={`Details for ${listTitle}`}
                >
                  <Arrow />
                </a>
              </Link>
            </td>
          </tr>
        );
      })}
    </tbody>
  );
}

JobTableRows.propTypes = {
  items: PropTypes.arrayOf(JobShape),
};

export default JobTableRows;
