import React, { useState, useRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { useIntersectionEffect } from '../../../shared/hooks/use-intersection-effect/use-intersection-effect';

import styles from './section-indicator.module.scss';

export const ANIMATION_TYPES = {
  ONLY_HORIZONTAL: 'onlyHorizontal',
  FULL: 'full',
};

function SectionIndicator({
  headline,
  animationType = ANIMATION_TYPES.ONLY_HORIZONTAL,
}) {
  const onlyHorizontalAnimation =
    animationType === ANIMATION_TYPES.ONLY_HORIZONTAL;

  const sectionIndicatorElement = useRef(null);
  const [transitionStart, setTransitionStart] = useState(false);

  const activateTransition = () => setTransitionStart(true);

  useIntersectionEffect(sectionIndicatorElement, activateTransition, {
    root: null,
    rootMargin: '0px',
    threshold: 1.0,
  });

  return (
    <header
      className={classNames(styles.wrapper, {
        [styles.onlyHorizontal]: onlyHorizontalAnimation,
      })}
    >
      <div className={styles.inner}>
        <div
          ref={sectionIndicatorElement}
          className={classNames(styles.sectionIndicator, {
            [styles.transition]: transitionStart,
          })}
        >
          <div className={styles.container}>
            {!onlyHorizontalAnimation && (
              <>
                <h2 className={styles.headlineVertical}>{headline}</h2>
                <div className={styles.lineVertical} />
              </>
            )}

            <h2 className={styles.headlineHorizontal}>{headline}</h2>
            <div className={styles.lineHorizontal} />
          </div>
        </div>
      </div>
    </header>
  );
}

SectionIndicator.displayName = 'SectionIndicator';

SectionIndicator.propTypes = {
  headline: PropTypes.string,
  animationType: PropTypes.oneOf(Object.values(ANIMATION_TYPES)),
  backgroundColor: PropTypes.string,
};

export default SectionIndicator;
