import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useForm, FormProvider } from 'react-hook-form';

import axios from 'axios';
import getConfig from 'next/config';
import FormData from 'form-data';
import camelCase from 'lodash/camelCase';

const {
  publicRuntimeConfig: { API_HOST },
} = getConfig();

import ConfirmationForm from './confirmation-form/confirmation-form';
import styles from './greenhouse-form.module.scss';

import { GreenhouseQuestionShape } from '@/shared/shapes';
import {
  getGoogleAnalyticsSessionId,
  getGoogleAnalyticsUserId,
} from '@/services/cookies/cookies';
import { useTranslatedContent, APPLICATION_FORM } from '@/shared/content';
import {
  CTA_TYPE,
  EVENTS,
} from '@/shared/hooks/use-tracking/data-layer-variables';
import useTracking from '@/shared/hooks/use-tracking/use-tracking';

import Button from '@/components/global/button/button';
import Link from '@/components/global/link/link';
import CheckboxInput from '@/components/content/job/greenhouse-form/checkbox-input/checkbox-input';
import DropdownInput from '@/components/content/job/greenhouse-form/dropdown-input/dropdown-input';
import FileInput from '@/components/content/job/greenhouse-form/file-input/file-input';
import HiddenInput from '@/components/content/job/greenhouse-form/hidden-input/hidden-input';
import TextInput from '@/components/content/job/greenhouse-form/text-input/text-input';
import DateInput from '@/components/content/job/greenhouse-form/date-input/date-input';
import { showToast } from '@/components/global/toast/toast';

const mapQuestionToRenderedComponent = (question = {}, lang) => {
  const { type, name } = question;

  switch (type) {
    case 'short_text':
    case 'long_text':
      return (
        <div className={classNames(styles.field, styles.half)} key={name}>
          <TextInput question={question} lang={lang} />
        </div>
      );
    case 'boolean':
      return (
        <div className={classNames(styles.field, styles.shallow)} key={name}>
          <CheckboxInput question={question} lang={lang} />
        </div>
      );
    case 'attachment':
      return (
        <div className={classNames(styles.field, styles.third)} key={name}>
          <FileInput question={question} lang={lang} />
        </div>
      );
    case 'single_select':
      return (
        <div className={classNames(styles.field, styles.half)} key={name}>
          <DropdownInput question={question} lang={lang} />
        </div>
      );
    default:
      return null;
  }
};

function GreenhouseForm({ jobPostId, questions, lang, trackingData }) {
  const t = useTranslatedContent(APPLICATION_FORM, lang);

  const [isSubmitting, setIsSubmitting] = useState();
  const [isSubmitted, setIsSubmitted] = useState(false);

  const { pushTracking } = useTracking();

  const formMethods = useForm({
    mode: 'onBlur',
  });

  const { handleSubmit, reset, setError } = formMethods;

  const onSubmissionSuccess = () => {
    pushTracking({
      event: EVENTS.JOB_SUBMISSION,
      ...trackingData,
    });
    setIsSubmitted(true);
  };

  const onSubmissionFailure = response => {
    if (response && response.status === 400) {
      const { invalidFields } = response.data;

      Object.keys(invalidFields).forEach(fieldName => {
        setError(mappedQuestions[fieldName].name, {
          type: invalidFields[fieldName],
          message: t[invalidFields[fieldName]],
        });
      });

      formElementRef.current.scrollIntoView({ behavior: 'smooth' });
      return showToast(t.SUBMISSION_FAILURE_FORM_VALIDATION);
    }

    return showToast(t.SUBMISSION_FAILURE_UNKNOWN);
  };

  const onSubmit = async (data, event) => {
    event.preventDefault();
    setIsSubmitting(true);

    const url = `${API_HOST}/jobs/application/`;

    const formData = new FormData();

    Object.entries(data).forEach(([key, value]) => {
      if (value instanceof FileList) {
        if (value[0]) {
          formData.append(key, value[0], value[0].name);
        }
      } else {
        formData.append(key, value || '');
      }
    });

    try {
      (await axios.post(url, formData)).data;
      onSubmissionSuccess();
    } catch ({ response }) {
      onSubmissionFailure(response);
    } finally {
      setIsSubmitting(false);
    }
  };

  const [mappedQuestions] = useState(
    questions.reduce((mappedQuestions, question) => {
      const transformedQuestion = camelCase(question.label);

      return {
        ...mappedQuestions,
        [transformedQuestion]: question,
      };
    }, {})
  );

  const {
    firstName,
    lastName,
    email,
    phone,
    birthday,
    availableFrom,
    currentLocation,
    salaryExpectationsInEurGrossPerYear: salary,
    resume,
    coverLetter,
    otherFile,
    howDidYouHearAboutThisJob,
    linkedInProfile,
    sessionIdGoogleAnalytics,
    userIdGoogleAnalytics,
    ...restQuestions
  } = mappedQuestions;

  const formElementRef = useRef(null);

  useEffect(() => {
    if (isSubmitted) {
      reset({
        [firstName.name]: '',
        [lastName.name]: '',
        [email.name]: '',
        [phone.name]: '',
        [currentLocation.name]: '',
        [howDidYouHearAboutThisJob.name]: '',
        [resume.name]: [],
        [otherFile.name]: [],
        jobId: jobPostId,
        [linkedInProfile.name]: '',
        ...(birthday && { [birthday.name]: '' }),
        ...(availableFrom && { [availableFrom.name]: '' }),
        ...(salary && { [salary.name]: '' }),
        ...(coverLetter && { [coverLetter.name]: [] }),
        [sessionIdGoogleAnalytics.name]: getGoogleAnalyticsSessionId(),
        [userIdGoogleAnalytics.name]: getGoogleAnalyticsUserId(),
      }); // re-initialize inputs with correct initial values, in case of a second submission
    }
  }, [
    reset,
    isSubmitted,
    firstName.name,
    lastName.name,
    email.name,
    phone.name,
    currentLocation.name,
    howDidYouHearAboutThisJob.name,
    resume.name,
    otherFile.name,
    jobPostId,
    linkedInProfile.name,
    birthday,
    availableFrom,
    salary,
    coverLetter,
    sessionIdGoogleAnalytics,
    userIdGoogleAnalytics,
  ]);

  return (
    <FormProvider {...formMethods}>
      <form
        className={styles.form}
        onSubmit={handleSubmit(onSubmit)}
        method="POST"
        ref={formElementRef}
      >
        {isSubmitted ? (
          <ConfirmationForm lang={lang} />
        ) : (
          <>
            <HiddenInput
              question={{
                name: 'jobId',
                value: jobPostId,
              }}
            />
            <HiddenInput
              question={{
                ...userIdGoogleAnalytics,
                value: getGoogleAnalyticsUserId(),
              }}
            />
            <HiddenInput
              question={{
                ...sessionIdGoogleAnalytics,
                value: getGoogleAnalyticsSessionId(),
              }}
            />
            <div className={classNames(styles.fields, styles.column)}>
              <h3 className={styles.headline}>
                {t.SECTION_PERSONAL_DETAILS_TITLE}
              </h3>

              {mapQuestionToRenderedComponent(firstName, lang)}
              {mapQuestionToRenderedComponent(lastName, lang)}

              <div className={classNames(styles.field, styles.half)}>
                <TextInput question={email} type="email" lang={lang} />
              </div>

              {mapQuestionToRenderedComponent(phone, lang)}

              {birthday && (
                <div className={classNames(styles.field, styles.half)}>
                  <DateInput
                    question={birthday}
                    maxDate={new Date()}
                    lang={lang}
                  />
                </div>
              )}

              {availableFrom && (
                <div className={classNames(styles.field, styles.half)}>
                  <DateInput
                    question={availableFrom}
                    minDate={new Date()}
                    lang={lang}
                  />
                </div>
              )}

              {mapQuestionToRenderedComponent(currentLocation, lang)}
              {mapQuestionToRenderedComponent(salary, lang)}
              {mapQuestionToRenderedComponent(linkedInProfile, lang)}

              {Object.values(restQuestions).map(question =>
                mapQuestionToRenderedComponent(question, lang)
              )}
            </div>
            <div className={styles.fields}>
              <h3 className={styles.headline}>
                {t.SECTION_PROFESSIONAL_DETAILS_TITLE}
              </h3>
              <div className={styles.caption}>{t.FILE_INPUT_CTA}</div>

              {mapQuestionToRenderedComponent(resume, lang)}
              {mapQuestionToRenderedComponent(coverLetter, lang)}
              {mapQuestionToRenderedComponent(otherFile, lang)}
            </div>
            <div className={classNames(styles.fields, styles.column)}>
              <h3 className={styles.headline}>{t.SECTION_ABOUT_TITLE}</h3>

              <div className={classNames(styles.field, styles.half)}>
                <DropdownInput
                  question={howDidYouHearAboutThisJob}
                  lang={lang}
                />
              </div>
            </div>
            <div className={styles.fields}>
              <div className={styles.text}>
                {t.DATA_CONSENT_INITIAL}{' '}
                <a
                  href="mailto:jobs@project-a.com"
                  onClick={() =>
                    pushTracking({
                      event: EVENTS.CLICK_CTA_JOB,
                      ctaType: CTA_TYPE.CAREER_LINKS,
                      label: `jobs@project-a.com`,
                    })
                  }
                >
                  jobs@project-a.com
                </a>
                . {t.DATA_CONSENT_UNSUBSCRIBE_DESCRIPTION}{' '}
                <Link
                  href="/privacy-policy-application-management-system"
                  trackingData={{
                    event: EVENTS.CLICK_CTA_JOB,
                    ctaType: CTA_TYPE.CAREER_PRIVACY_POLICY,
                    label: t.DATA_APPLICATION_MANAGEMENT_SYSTEM,
                  }}
                  isExternalUrl
                >
                  <a>{t.DATA_APPLICATION_MANAGEMENT_SYSTEM}</a>
                </Link>
                .
              </div>
              <div className={styles.text}>{t.DATA_CONSENT_GREENHOUSE_USA}</div>

              <div className={styles.buttonWrapper}>
                <Button
                  title={
                    isSubmitting
                      ? t.JOB_SUBMISSION_LOADING
                      : t.JOB_SUBMISSION_CTA
                  }
                  type="submit"
                  modifiers={['primary', 'hoverBlue', 'large']}
                  disabled={isSubmitting}
                />
              </div>
            </div>
          </>
        )}
      </form>
    </FormProvider>
  );
}

GreenhouseForm.propTypes = {
  jobPostId: PropTypes.number.isRequired,
  questions: PropTypes.arrayOf(GreenhouseQuestionShape).isRequired,
  lang: PropTypes.oneOf(['EN', 'DE']),
  trackingData: PropTypes.shape({
    jobName: PropTypes.string,
    speciality: PropTypes.string,
    seniorityLevel: PropTypes.string,
    employmentType: PropTypes.string,
    organization: PropTypes.string,
    location: PropTypes.string,
  }),
};

export default GreenhouseForm;
