import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './unit-value-input.module.scss';

function UnitValueInput({ modifiers = [], value, unit, onChange }) {
  return (
    <div
      className={classNames(
        styles.unitValue,
        modifiers.map(modifier => styles[modifier])
      )}
    >
      <input
        className={styles.input}
        type="number"
        min="0"
        placeholder="0"
        value={value === 0 ? '' : value}
        onChange={({ currentTarget: { value: newValue } }) =>
          onChange(parseFloat(newValue || 0))
        }
      />
      {unit && <span className={styles.unit}>{unit}</span>}
    </div>
  );
}

UnitValueInput.propTypes = {
  modifiers: PropTypes.arrayOf(PropTypes.oneOf(['salary'])),
  value: PropTypes.number.isRequired,
  unit: PropTypes.string,
  precision: PropTypes.number,
  onChange: PropTypes.func.isRequired,
};

export default UnitValueInput;
