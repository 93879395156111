import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { EVENTS } from '../../../../../shared/hooks/use-tracking/data-layer-variables';
import { getTransformationMediaUrl } from '../../../../../shared/utils/url/url';
import ArrowBox from '../../../../global/arrow-box/arrow-box';
import Link from '../../../../global/link/link';
import { getProfileLink } from '../../helper';
import EmployeeSummary from './employee-summary/employee-summary';
import styles from './tile.module.scss';

const PORTRAIT_CONFIG = {
  w: 364,
  fm: 'jpg',
};

const Tile = ({
  item,
  index,
  highlightItemIndex,
  setHighlightItemIndex,
  className,
}) => {
  const {
    picture,
    headline,
    subheadline,
    slug,
    customLink,
    socialMedia,
    tag,
    position,
    about,
  } = item;

  const profileLink = getProfileLink(slug, customLink, about);

  return (
    <div
      className={classNames(styles.container, className)}
      onMouseEnter={() => setHighlightItemIndex(index)}
      onMouseLeave={() => setHighlightItemIndex(null)}
    >
      {profileLink ? (
        <Link
          href={profileLink.link}
          isExternalUrl={profileLink.isExternal}
          trackingData={{
            event: EVENTS.CLICK_CTA_TEAM,
            ctaType: profileLink.ctaType,
            label: profileLink.link,
            teamMemberName: headline,
          }}
        >
          <a className={styles.content}>
            {picture && (
              <div className={styles.pictureArea}>
                <picture>
                  <source
                    srcSet={getTransformationMediaUrl(picture, PORTRAIT_CONFIG)}
                  />
                  <img
                    src={getTransformationMediaUrl(picture, PORTRAIT_CONFIG)}
                    alt={picture.description}
                  />
                </picture>

                <div className={styles.arrowBox}>
                  <ArrowBox focused={index === highlightItemIndex} />
                </div>
              </div>
            )}
          </a>
        </Link>
      ) : (
        <>
          {picture && (
            <div className={styles.pictureArea}>
              <picture>
                <source
                  srcSet={getTransformationMediaUrl(picture, PORTRAIT_CONFIG)}
                />
                <img
                  src={getTransformationMediaUrl(picture, PORTRAIT_CONFIG)}
                  alt={picture.description}
                />
              </picture>
            </div>
          )}
        </>
      )}
      <EmployeeSummary
        headline={headline}
        subheadline={subheadline || position}
        socialMedia={socialMedia}
        link={profileLink}
        tag={tag}
      />
    </div>
  );
};
Tile.propTypes = {
  item: PropTypes.any,
  index: PropTypes.number,
  highlightItemIndex: PropTypes.number,
  setHighlightItemIndex: PropTypes.func,
  className: PropTypes.string,
};

export default Tile;
