import React from 'react';
import PropTypes from 'prop-types';

import styles from './values-section.module.scss';

function ValuesSection({ content }) {
  return (
    <section className={styles.valuesSection}>
      <div className={styles.inner}>
        <ul className={styles.values}>
          {content.map(({ headline, details }, index) => (
            <li className={styles.value} key={index}>
              <h3 className={styles.headline}>{headline}</h3>
              <div className={styles.details}>
                <div className={styles.detailsInner}>{details}</div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
}

ValuesSection.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      headline: PropTypes.string,
      details: PropTypes.string,
    })
  ),
};

export default ValuesSection;
