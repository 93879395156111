import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { RichTextShape } from '../../../../shared/shapes';
import Arrow from '../../../../shared/icons/short-arrow.svg';
import styles from './arrow-list.module.scss';

export const arrowListItem = {
  // eslint-disable-next-line react/display-name
  [BLOCKS.LIST_ITEM]: (_, children) => {
    return (
      <li>
        <Arrow width={12} />
        {children}
      </li>
    );
  },
};

function ArrowList({ content, richTextOptions, className }) {
  const combinedOptions = {
    renderNode: { ...arrowListItem, ...richTextOptions.renderNode },
    renderText: richTextOptions.renderText,
  };

  return (
    <div className={classNames(styles.list, className)}>
      {documentToReactComponents(content, combinedOptions)}
    </div>
  );
}

ArrowList.propTypes = {
  content: RichTextShape,
  richTextOptions: PropTypes.shape({
    renderNode: PropTypes.object,
    renderText: PropTypes.func,
  }),
  className: PropTypes.string,
};

export default ArrowList;
