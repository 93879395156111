import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import get from 'lodash/get';
import debounce from 'lodash/debounce';

import { SlideDown } from 'react-slidedown';
import 'react-slidedown/lib/slidedown.css';

import {
  EVENTS,
  CTA_TYPE,
} from '../../../shared/hooks/use-tracking/data-layer-variables';
import { ALL_EYES_ON } from '../../../shared/content';
import { MEDIA_QUERIES, PICTURE_CONFIG } from '../../../shared/constants.mjs';
import { TeamMemberShape } from '../../../shared/shapes';
import { getTransformationMediaUrl } from '../../../shared/utils/url/url';

import Slider, { MODIFIERS } from '../../../components/global/slider/slider';
import Link from '../../../components/global/link/link';
import ArrowBox from '../../../components/global/arrow-box/arrow-box';
import ArrowLink from '../../../components/global/arrow-link/arrow-link';

import styles from './team-member-slider.module.scss';

const determineSlideWidth = () => {
  if (typeof window !== 'undefined') {
    if (window.matchMedia(MEDIA_QUERIES.LARGE).matches) {
      return 33.333;
    }

    if (window.matchMedia(MEDIA_QUERIES.MEDIUM).matches) {
      return 50;
    }
  }
  return 100;
};

function TeamMemberSlider({ teamMemberItems }) {
  const [focused, setFocused] = useState(0);
  const [slideWidth, setSlideWidth] = useState(null);
  const [highlightItemIndex, setHighlightItemIndex] = useState(null);

  useEffect(() => {
    const resizeHandler = debounce(
      () => setSlideWidth(determineSlideWidth()),
      250
    );

    setSlideWidth(determineSlideWidth());
    window.addEventListener('resize', resizeHandler);

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  const itemModels = teamMemberItems.map(teamMember => ({
    ...teamMember,
    ...teamMember.allEyesOn,
  }));

  const getLinkLabel = model =>
    get(model, 'link.label') || ALL_EYES_ON.DEFAULT_LINK_LABEL;

  const handleClick = (event, index) => {
    if (index !== focused) {
      event.preventDefault();
    }
  };

  return (
    <section className={styles.teamMemberSlider}>
      <div className={styles.inner}>
        <SlideDown>
          {slideWidth && (
            <Slider
              width={slideWidth}
              modifiers={[MODIFIERS.MULTI, MODIFIERS.ADVANCED_CONTROLS]}
              onChange={setFocused}
              trackingEventType={EVENTS.ALL_EYES_ON}
            >
              {itemModels.map((model, index) => (
                <div
                  className={classNames(styles.slideContent, {
                    [styles.focused]: index === focused,
                  })}
                  key={model.id}
                >
                  {model.link && (
                    <Link
                      {...model.link}
                      href={model.link.url}
                      trackingData={{
                        event: EVENTS.ALL_EYES_ON_CTA,
                        ctaType: CTA_TYPE.ITEM_SLIDER_IMAGE,
                        label: model.link.name,
                      }}
                    >
                      <a
                        onClick={event => handleClick(event, index)}
                        aria-label={getLinkLabel(model)}
                      >
                        <div
                          className={classNames(styles.pictureArea, {
                            [styles.highlightItem]:
                              index === highlightItemIndex,
                          })}
                          onMouseEnter={() => setHighlightItemIndex(index)}
                          onMouseLeave={() => setHighlightItemIndex(null)}
                        >
                          <picture>
                            <source
                              srcSet={getTransformationMediaUrl(
                                model.picture,
                                PICTURE_CONFIG
                              )}
                            />
                            <img
                              className={styles.picture}
                              src={getTransformationMediaUrl(
                                model.picture,
                                PICTURE_CONFIG
                              )}
                              alt={model.picture.title}
                            />
                          </picture>

                          <div className={styles.arrowBox}>
                            <ArrowBox
                              label={getLinkLabel(model)}
                              onClick={event => handleClick(event, index)}
                              focused={index === highlightItemIndex}
                            />
                          </div>
                        </div>
                      </a>
                    </Link>
                  )}

                  <p className={styles.firstName}>{model.firstName}</p>
                  <p className={styles.position}>{model.position}</p>

                  {documentToReactComponents(model.quote)}

                  {model.link && (
                    <div className={styles.link}>
                      <ArrowLink
                        {...model.link}
                        href={model.link.url}
                        label={getLinkLabel(model)}
                        onMouseEnter={() => setHighlightItemIndex(index)}
                        onMouseLeave={() => setHighlightItemIndex(null)}
                        trackingData={{
                          event: EVENTS.ALL_EYES_ON_CTA,
                          ctaType: CTA_TYPE.ITEM_SLIDER_TEXT,
                          label: model.link.name,
                        }}
                      />
                    </div>
                  )}
                </div>
              ))}
            </Slider>
          )}
        </SlideDown>
      </div>
    </section>
  );
}

TeamMemberSlider.propTypes = {
  teamMemberItems: PropTypes.arrayOf(TeamMemberShape),
};

export default TeamMemberSlider;
