import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { slugify } from '../../shared/utils/url/url';
import { createLayout } from './layout-model/layout-model';

import styles from './layout.module.scss';

function Layout({ children }) {
  const { heroSection, contentSections, contentItemDetailSection } =
    createLayout(children);

  return (
    <div>
      {contentItemDetailSection ? (
        <>
          <section>{contentItemDetailSection}</section>
        </>
      ) : (
        <>
          <section className={styles.heroSection}>{heroSection}</section>

          {contentSections.map(({ indicator, subSections }, index) => (
            <section
              id={slugify(indicator.props.title)}
              className={classNames(
                styles.contentSection,
                styles[indicator.props.backgroundColor],
                {
                  [styles.hasBackground]: indicator.props.backgroundColor,
                }
              )}
              key={index}
            >
              {indicator}
              {subSections}
            </section>
          ))}
        </>
      )}
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
};

export default Layout;
