import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { CTA_TYPE } from '../../../shared/hooks/use-tracking/data-layer-variables';
import useTracking from '../../../shared/hooks/use-tracking/use-tracking';
import { MODIFIERS } from './slider';
import styles from './slider.module.scss';

export default function SliderControls({
  numberOfItems,
  focusedIndex,
  previousSlide,
  nextSlide,
  onDotClick,
  modifiers,
  trackingEventType,
}) {
  const { pushTracking } = useTracking();
  const trackingData = {
    event: trackingEventType,
    ctaType: CTA_TYPE.SLIDER_CONTROL_ARROWS,
    totalPages: numberOfItems,
  };

  const isFirstSlideFocused = focusedIndex === 0;
  const isLastSlideFocused = focusedIndex === numberOfItems - 1;
  const placeholderDotsArray = new Array(numberOfItems).fill(0);

  return (
    <div
      className={classNames(
        styles.imageSlider,
        modifiers.map(className => styles[className])
      )}
    >
      {numberOfItems > 1 && !modifiers.includes(MODIFIERS.ADVANCED_CONTROLS) && (
        <div className={styles.controls}>
          <button
            className={classNames(styles.control, {
              [styles.disabled]: isFirstSlideFocused,
            })}
            onClick={previousSlide}
          >
            &lt;
          </button>

          <div className={styles.status}>
            {focusedIndex + 1} / {numberOfItems}
          </div>

          <button
            className={classNames(styles.control, {
              [styles.disabled]: isLastSlideFocused,
            })}
            onClick={nextSlide}
          >
            &gt;
          </button>
        </div>
      )}

      {numberOfItems > 1 && modifiers.includes(MODIFIERS.ADVANCED_CONTROLS) && (
        <div className={styles.controls}>
          <div className={styles.status}>
            {placeholderDotsArray.map((slide, index) => (
              <div
                className={classNames(styles.statusDot, {
                  [styles.focusedDot]: index === focusedIndex,
                })}
                onClick={() => {
                  onDotClick(index);
                  pushTracking({
                    ...trackingData,
                    ctaType: CTA_TYPE.SLIDER_CONTROL_DOTS,
                    currentPage: index + 1,
                  });
                }}
                key={`slide-control-${index}`}
              />
            ))}
          </div>

          <div className={styles.buttons}>
            <button
              className={classNames(styles.control, styles.previousControl, {
                [styles.disabled]: isFirstSlideFocused,
              })}
              onClick={previousSlide}
            >
              <div className={styles.arrow} />
            </button>

            <button
              className={classNames(styles.control, styles.nextControl, {
                [styles.disabled]: isLastSlideFocused,
              })}
              onClick={nextSlide}
            >
              <div className={styles.arrow} />
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

SliderControls.propTypes = {
  numberOfItems: PropTypes.number.isRequired,
  focusedIndex: PropTypes.number.isRequired,
  previousSlide: PropTypes.func.isRequired,
  nextSlide: PropTypes.func.isRequired,
  onDotClick: PropTypes.func.isRequired,
  modifiers: PropTypes.arrayOf(PropTypes.string),
  trackingEventType: PropTypes.string.isRequired,
};
