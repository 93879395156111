import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { FilterGroupShape } from '../../../../../shared/shapes';
import { slugify } from '../../../../../shared/utils/url/url';

import styles from './filter-dropdown.module.scss';
import CaretDownIcon from './caret-down.svg';

const DEFAULT_OPTION = {
  label: 'All',
  value: '',
};

function FilterDropdown({ group, handleChange }) {
  const [selectedOption, setSelectedOption] = useState(DEFAULT_OPTION);

  const key = slugify(group.label);

  const updateValue = ({ currentTarget }, group) => {
    const selectedOption =
      group.options.find(({ value }) => currentTarget.value === value) ||
      DEFAULT_OPTION;

    setSelectedOption(selectedOption);
    handleChange(group, selectedOption);
  };

  return (
    <div className={styles.filterDropdown}>
      <label className={styles.label} htmlFor={key}>
        {group.label}

        <span className={styles.icon}>
          <CaretDownIcon width={10} />
        </span>
      </label>

      <div className={styles.valueWrapper}>
        <div className={styles.value}>{selectedOption.label}</div>
      </div>

      <select
        className={styles.select}
        id={key}
        onChange={event => updateValue(event, group)}
        value={selectedOption.value}
      >
        <option value={DEFAULT_OPTION.value}>{DEFAULT_OPTION.label}</option>

        {group.options.map(({ value, label, enabled }) => {
          const optionKey = `${key}.${slugify(value)}`;
          return (
            <option disabled={!enabled} key={optionKey} value={value}>
              {label}
            </option>
          );
        })}
      </select>
    </div>
  );
}

FilterDropdown.propTypes = {
  group: FilterGroupShape,
  handleChange: PropTypes.func,
};

export default FilterDropdown;
