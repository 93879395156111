import PropTypes from 'prop-types';
import React from 'react';
import {
  CTA_TYPE,
  EVENTS,
} from '../../../../../../shared/hooks/use-tracking/data-layer-variables';
import Link from '../../../../../global/link/link';
import styles from './employee-summary.module.scss';

export default function EmployeeSummary({
  headline,
  subheadline,
  socialMedia,
  link,
  tag,
}) {
  const mainSocialMedia = socialMedia && socialMedia[0];

  const inner = (
    <div>
      <p className={styles.name}> {headline}</p>
      {subheadline && <p className={styles.position}>{subheadline}</p>}
      {tag && <p className={styles.tag}>{tag}</p>}
    </div>
  );

  return (
    <div className={styles.summaryContainer}>
      {link ? (
        <Link
          href={link.link}
          isExternalUrl={link.isExternal}
          trackingData={{
            event: EVENTS.CLICK_CTA_TEAM,
            ctaType: link.ctaType,
            label: link.link,
            teamMemberName: headline,
          }}
        >
          <a className={styles.content}>{inner}</a>
        </Link>
      ) : (
        <>{inner}</>
      )}
      {mainSocialMedia && mainSocialMedia.icon && (
        <Link
          href={mainSocialMedia.url}
          isExternalUrl={true}
          trackingData={{
            event: EVENTS.CLICK_CTA_TEAM,
            ctaType: CTA_TYPE.TEAM_SOCIAL_MEDIA,
            label: mainSocialMedia.url,
            teamMemberName: headline,
          }}
        >
          <a className={styles.socialLink}>
            <picture>
              <source srcSet={mainSocialMedia.icon.file.url} />
              <img
                src={mainSocialMedia.icon.file && mainSocialMedia.icon.file.url}
                alt={mainSocialMedia.icon.title}
              />
            </picture>
          </a>
        </Link>
      )}
    </div>
  );
}

EmployeeSummary.propTypes = {
  headline: PropTypes.string.isRequired,
  subheadline: PropTypes.string,
  socialMedia: PropTypes.array,
  link: PropTypes.shape({
    link: PropTypes.string,
    isExternal: PropTypes.bool,
    ctaType: PropTypes.oneOf(Object.values(CTA_TYPE)),
  }),
  tag: PropTypes.string,
};
