import classNames from 'classnames';
import noop from 'lodash/noop';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { EVENTS } from '../../../shared/hooks/use-tracking/data-layer-variables';
import SliderControls from './slider-controls';
import styles from './slider.module.scss';
import useSlider from './useSlider';

function Slider({
  children = [],
  onChange = noop,
  width = 100,
  modifiers = [],
  trackingEventType,
}) {
  const visibleCount = 100 / width;
  const [scrollIndex, setScrollIndex] = useState(0);
  const { previousSlide, nextSlide, focusedIndex, setFocusedIndex } = useSlider(
    children,
    trackingEventType
  );

  const updateScrollIndex = useCallback(() => {
    if (focusedIndex > scrollIndex + (visibleCount - 1)) {
      setScrollIndex(focusedIndex - (visibleCount - 1));
    } else if (focusedIndex < scrollIndex) {
      setScrollIndex(focusedIndex);
    }
  }, [focusedIndex, scrollIndex, visibleCount]);

  useEffect(() => {
    onChange(focusedIndex);
    updateScrollIndex();
  }, [focusedIndex, onChange, updateScrollIndex]);

  useEffect(() => {
    validateModifiers({ modifiers, width });
  }, [modifiers, width]);

  return (
    <div
      className={classNames(
        styles.imageSlider,
        modifiers.map(className => styles[className])
      )}
    >
      <div className={styles.slides} style={{ width: `${width}%` }}>
        <div
          className={styles.slidesInner}
          style={{
            width: `${100 * children.length}%`,
            transform: `translateX(-${(100 / children.length) * scrollIndex}%)`,
          }}
        >
          {children.map((slide, index) => (
            <div
              key={`slide-${slide.key}`}
              style={{
                width: `${100 / children.length}%`,
              }}
              className={classNames(styles.itemContainer, {
                [styles.focused]: index === focusedIndex,
              })}
              onClick={
                modifiers.includes(MODIFIERS.MULTI)
                  ? () => setFocusedIndex(index)
                  : noop
              }
            >
              {slide}
            </div>
          ))}
        </div>
      </div>

      <SliderControls
        numberOfItems={children.length}
        focusedIndex={focusedIndex}
        onDotClick={index => setFocusedIndex(index)}
        previousSlide={previousSlide}
        nextSlide={nextSlide}
        modifiers={modifiers}
        trackingEventType={trackingEventType}
      />
    </div>
  );
}

export const MODIFIERS = {
  FULL_WIDTH: 'fullWidth',
  MULTI: 'multi',
  ADVANCED_CONTROLS: 'advancedControls',
};

const validateModifiers = ({ modifiers, width }) => {
  if (modifiers.includes(MODIFIERS.MULTI) && !width) {
    throw new Error(`No slide width provided for 'multi' type slider`);
  }
};

Slider.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  onChange: PropTypes.func,
  width: PropTypes.number,
  modifiers: PropTypes.arrayOf(PropTypes.string),
  trackingEventType: PropTypes.oneOf(Object.values(EVENTS)),
};

export default Slider;
