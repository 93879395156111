import PropTypes from 'prop-types';
import React from 'react';
import cN from 'classnames';
import nl2br from 'react-nl2br';
import { RichTextShape } from '../../../shared/shapes';
import { linkNode } from '../../../shared/utils/rich-text';
import ArrowList from './arrow-list/arrow-list';
import styles from './wysiwyg.module.scss';

const RICH_TEXT_OPTIONS = {
  renderText: text => nl2br(text),
  renderNode: {
    ...linkNode,
  },
};
function Wysiwyg({ headline, subheadline, content, boxed }) {
  return (
    <div className={styles.inner}>
      <div className={cN(styles.text, { [styles.boxed]: boxed })}>
        {headline && <h1 className={styles.headline}>{headline}</h1>}

        {subheadline && <h2 className={styles.subheadline}>{subheadline}</h2>}

        <ArrowList
          content={content}
          richTextOptions={RICH_TEXT_OPTIONS}
          className={styles.content}
        />
      </div>
    </div>
  );
}

Wysiwyg.propTypes = {
  headline: PropTypes.string,
  subheadline: PropTypes.string,
  content: RichTextShape,
  boxed: PropTypes.bool,
};

export default Wysiwyg;
