export const TEAM_MEMBER_TYPES = {
  OPERATIONS: 'Operations',
  MANAGEMENT: 'Management',
  TEAM_LEADS: 'Team Leads',
  INVESTMENT: 'Investment',
};

export const TEAM_MEMBER_LIST_MAPPING = {
  Leads: TEAM_MEMBER_TYPES.TEAM_LEADS,
  Investment: TEAM_MEMBER_TYPES.INVESTMENT,
};

export const FAQ_TYPES = {
  OPERATIONAL_VC: 'Operational VC',
  INVESTMENT: 'Investment',
  ABOUT: 'About Us',
  HOW_TO_APPROACH_US: 'How to approach us',
  INVESTMENT_CRITERIA: 'What is your investment criteria',
  PROCESS: 'Process',
  WORKING_AT_PROJECT_A: 'Work with Project A',
  STUDIO: 'Studio',
};

export const FAQ_LIST_MAPPING = {
  'Operational VC FAQ': FAQ_TYPES.OPERATIONAL_VC,
  'Investment FAQ': FAQ_TYPES.INVESTMENT,
  'About Us FAQ': FAQ_TYPES.ABOUT,
  'How to approach us FAQ': FAQ_TYPES.HOW_TO_APPROACH_US,
  'What is your investment criteria FAQ': FAQ_TYPES.INVESTMENT_CRITERIA,
  'Process FAQ': FAQ_TYPES.PROCESS,
  'Work with Project A FAQ': FAQ_TYPES.WORKING_AT_PROJECT_A,
  'Studio FAQ': FAQ_TYPES.STUDIO,
};

export const CONTENT_ITEM_TYPES = {
  KNOWLEDGE_ITEM: 'Knowledge Item',
  PRESS_RELEASE: 'Press Release',
  BLOG: 'Blog',
  PODCAST: 'Podcast',
  VIDEO: 'Video',
};

export const DOWNLOAD_TYPES = {
  MEDIA_ASSETS: 'Media Assets',
  PRESS_KIT_PARTNERS: 'Press Kit Partners',
};

export const VENTURE_FILTER_GROUPS_CONFIG = [
  {
    property: 'businessModel',
    label: 'Business Model',
    active: true,
  },
  {
    property: 'segment',
    label: 'Industry',
    active: true,
  },
  {
    property: 'investmentType',
    label: 'Type of Investment',
    active: true,
  },
];

export const KNOWLEDGE_FILTER_GROUPS_CONFIG = [
  {
    property: 'topic',
    label: 'Topic',
    active: true,
  },
  {
    property: 'mediaType',
    label: 'Media',
    active: true,
  },
];

export const TEAM_FILTER_CONFIG = [
  {
    property: 'type',
    label: 'Area',
    active: true,
  },
  {
    property: 'team',
    label: 'Team',
    active: true,
  },
];

export const JOBS_FILTER_GROUPS_CONFIG = [
  {
    property: 'greenhouseData.departments',
    label: 'Speciality',
    active: true,
  },
  {
    property: 'greenhouseData.seniorityLevel',
    label: 'Experience',
    active: true,
  },
  {
    property: 'greenhouseData.employmentType',
    label: 'Employment',
    active: true,
  },
  {
    property: 'greenhouseData.organization',
    label: 'Organisation',
    active: true,
  },
  {
    property: 'greenhouseData.location',
    label: 'Location',
    active: true,
  },
];

export const MEDIA_QUERIES = {
  SMALL: '(min-width: 400px)',
  MEDIUM: '(min-width: 768px)',
  LARGE: '(min-width: 1024px)',
  XLARGE: '(min-width: 1441px)',
};

export const ENV_NAMES = {
  LOCAL: 'local',
  PREVIEW: 'preview',
  STAGING: 'staging',
  LIVE: 'live',
};

export const PAGE_LOCATIONS = {
  BASE: 'Base',
  CAREERS: 'Careers',
  PRESS: 'Press',
  TEAM: 'Team',
  'KNOWLEDGE-BLOG': 'Knowledge-Blog',
  'KNOWLEDGE-VIDEO': 'Knowledge-Video',
  'KNOWLEDGE-PODCAST': 'Knowledge-Podcast',
};

export const PICTURE_CONFIG = {
  fm: 'jpg',
  bg: 'rgb:000000',
  w: 344,
};
