import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import { TeamMemberShape } from '../../../shared/shapes';
import Download from '../../../shared/icons/download.svg';
import Link from '../../../components/global/link/link';
import ArrowList from '../wysiwyg/arrow-list/arrow-list';

import styles from './partner.module.scss';

const OPTIONS = {
  renderText: text => text.replace('!', '?'),
};

function Partner(partner) {
  const { about, aboutFiles, responsibility, background } = partner;

  return (
    <div>
      <div className={styles.inner}>
        <div className={styles.content}>
          <article className={styles.about}>
            <div className={styles.aboutText}>
              {about && documentToReactComponents(about)}
            </div>

            {aboutFiles && (
              <div className={styles.aboutFiles}>
                {aboutFiles.map(({ file, title, description }, index) => (
                  <div key={index} className={styles.aboutFile}>
                    <Link isExternalUrl={true} href={file.url}>
                      <a title={description} className={styles.fileLink}>
                        <Download width={12} />
                        <span className={styles.label}>Download {title}</span>
                      </a>
                    </Link>
                  </div>
                ))}
              </div>
            )}
          </article>

          <aside className={styles.additional}>
            {responsibility && (
              <ArrowList
                content={responsibility}
                richTextOptions={OPTIONS}
                className={styles.additionalSection}
              />
            )}

            {background && (
              <ArrowList
                content={background}
                richTextOptions={OPTIONS}
                className={styles.additionalSection}
              />
            )}
          </aside>
        </div>
      </div>
    </div>
  );
}

Partner.propTypes = {
  partner: TeamMemberShape,
};

export default Partner;
