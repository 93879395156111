import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { getProfileLink } from '../../helper';
import EmployeeSummary from '../tile/employee-summary/employee-summary';
import styles from './employee-tiles-simple.module.scss';

const ITEM_LIMIT = 10;

export default function EmployeeTilesSimple({ items }) {
  const [isTruncated, setIsTruncated] = useState(false);

  useEffect(() => {
    setIsTruncated(items.length > ITEM_LIMIT);
  }, [items]);

  return (
    <section className={classNames({ [styles.showAll]: !isTruncated })}>
      <div className={styles.items}>
        {items.map((item, index) => (
          <div className={styles.item} key={index}>
            <EmployeeSummary
              socialMedia={item.socialMedia}
              headline={item.headline}
              subheadline={item.subheadline || item.position}
              link={getProfileLink(item.slug, item.customLink, item.about)}
            />
          </div>
        ))}
      </div>

      {isTruncated && (
        <div className={styles.moreWrapper}>
          <button className={styles.more} onClick={() => setIsTruncated(false)}>
            + View all {items.length} Team Members
          </button>
        </div>
      )}
    </section>
  );
}

EmployeeTilesSimple.propTypes = { items: PropTypes.any };
