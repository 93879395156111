import React from 'react';
import propTypes from 'prop-types';

import styles from './video-embed.module.scss';

function VideoEmbed({ title, videoId }) {
  return (
    <section className={styles.container}>
      <div className={styles.videoArea}>
        <iframe
          width="100%"
          height="100%"
          src={`https://www.youtube.com/embed/${videoId}?rel=0&modestbranding=1&color=white`}
          title={title}
          frameBorder="0"
          allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          style={{
            position: 'absolute',
            top: '0',
            maxHeight: '640px',
          }}
        />
      </div>
    </section>
  );
}

VideoEmbed.propTypes = {
  title: propTypes.string.isRequired,
  videoId: propTypes.string.isRequired,
};

export default VideoEmbed;
