export const getToggledFilterOption = ({
  filterGroups,
  group: selectedGroup,
  option: selectedOption,
  exclusive = false,
}) =>
  filterGroups.map(group => ({
    ...group,
    options:
      group.property !== selectedGroup.property
        ? group.options
        : group.options.map(option => ({
            ...option,
            active:
              option.value !== selectedOption.value
                ? exclusive
                  ? false
                  : option.active
                : !option.active,
          })),
  }));

export const getToggledFilterGroup = ({ filterGroups, group: selectedGroup }) =>
  filterGroups.map(group => ({
    ...group,
    active:
      group.property === selectedGroup.property
        ? !selectedGroup.active
        : group.active,
  }));
