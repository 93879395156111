import React from 'react';
import get from 'lodash/get';
import { INLINES } from '@contentful/rich-text-types';

import { getTransformationMediaUrl } from '../utils/url/url';
import Link from '../../components/global/link/link';
import { EVENTS } from '../hooks/use-tracking/data-layer-variables';

// NOTE see https://github.com/contentful/rich-text/tree/master/packages/rich-text-react-renderer for more possible options

export const linkNode = {
  // eslint-disable-next-line react/display-name
  [INLINES.ASSET_HYPERLINK]: node => {
    const media = get(node, 'data.target.fields');
    const labelText = get(node, 'content[0].value');
    const url = getTransformationMediaUrl(media);

    return (
      <Link
        href={url}
        trackingData={{
          event: EVENTS.CLICK_CONTENT_LINK,
          label: url,
        }}
        isExternalUrl
      >
        <a>{labelText}</a>
      </Link>
    );
  },
};

/* eslint-disable react/display-name */
export const RICH_TEXT_OPTIONS = {
  renderText: text => {
    return text.split('\n').reduce((children, textSegment, index) => {
      return [...children, index > 0 && <br key={index} />, textSegment];
    }, []);
  },
  renderNode: {
    [INLINES.ASSET_HYPERLINK]: node => {
      const media = get(node, 'data.target.fields');
      const labelText = get(node, 'content[0].value');
      const url = getTransformationMediaUrl(media);

      return (
        <Link href={url} isExternalUrl>
          <a>{labelText}</a>
        </Link>
      );
    },
  },
};

/* eslint-enable react/display-name */
