import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SlideDown } from 'react-slidedown';
import 'react-slidedown/lib/slidedown.css';

import { FilterGroupShape } from '../../../shared/shapes';
import useTracking from '../../../shared/hooks/use-tracking/use-tracking';
import { EVENTS } from '../../../shared/hooks/use-tracking/data-layer-variables';
import FilterToggleButton from './filter-toggle-button/filter-toggle-button';
import styles from './expanding-filters.module.scss';

function ExpandingFilters({ filterGroups, actions, trackingCtaType }) {
  const { pushTracking } = useTracking();

  return (
    <div>
      {filterGroups.map(group => (
        <div
          className={classNames(styles.filterGroup, {
            [styles.expanded]: group.active,
          })}
          key={group.property}
        >
          <div className={styles.groupToggleArea}>
            {group.active ? (
              <div className={styles.minusToggle} />
            ) : (
              <div className={styles.plusToggle} />
            )}

            <button
              className={styles.groupToggle}
              onClick={() => {
                pushTracking({
                  event: EVENTS.TOGGLE_FILTER_GROUP,
                  ctaType: trackingCtaType,
                  label: group.label,
                });
                actions.toggleFilterGroup(group);
              }}
            >
              {group.label}
            </button>
            <div className={styles.groupToggleLine} />
          </div>

          <div key={group.property} className={styles.groupOptions}>
            <SlideDown className={styles.slideDown} transitionOnAppear={false}>
              {group.active &&
                group.options.map(option => (
                  <FilterToggleButton
                    option={option}
                    toggleOption={() => {
                      pushTracking({
                        event: EVENTS.TOGGLE_FILTER,
                        ctaType: trackingCtaType,
                        label: option.value,
                        group: group.label,
                      });
                      actions.toggleFilterOption({ group, option });
                    }}
                    key={option.value}
                  />
                ))}
            </SlideDown>
          </div>
        </div>
      ))}
    </div>
  );
}

ExpandingFilters.propTypes = {
  filterGroups: PropTypes.arrayOf(FilterGroupShape),
  actions: PropTypes.objectOf(PropTypes.func),
  trackingCtaType: PropTypes.string.isRequired,
};

export default ExpandingFilters;
