import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './unit-value.module.scss';

function UnitValue({ modifiers = [], value, unit, precision = 2 }) {
  return (
    <div
      className={classNames(
        styles.unitValue,
        modifiers.map(modifier => styles[modifier])
      )}
    >
      {!isNaN(value) ? (
        <>
          <input
            className={styles.value}
            type="number"
            readOnly
            value={value.toFixed(precision)}
          />
          {unit && <span className={styles.unit}>{unit}</span>}
        </>
      ) : (
        '–'
      )}
    </div>
  );
}

UnitValue.propTypes = {
  modifiers: PropTypes.arrayOf(PropTypes.oneOf(['headline', 'large'])),
  value: PropTypes.number.isRequired,
  unit: PropTypes.string,
  precision: PropTypes.number,
};

export default UnitValue;
