import { useCallback, useState, useMemo } from 'react';
import { CTA_TYPE } from '../../../shared/hooks/use-tracking/data-layer-variables';
import useTracking from '../../../shared/hooks/use-tracking/use-tracking';

export default function useSlider(items, trackingEventType) {
  const [focusedIndex, setFocusedIndex] = useState(0);
  const { pushTracking } = useTracking();

  const trackingData = useMemo(
    () => ({
      event: trackingEventType,
      ctaType: CTA_TYPE.SLIDER_CONTROL_ARROWS,
      totalPages: items.length,
    }),
    [trackingEventType, items]
  );

  const previousSlide = useCallback(() => {
    const index = (focusedIndex - 1 + items.length) % items.length;

    pushTracking({
      ...trackingData,
      currentPage: index + 1,
    });

    setFocusedIndex(index);
  }, [focusedIndex, items, pushTracking, trackingData]);

  const nextSlide = useCallback(() => {
    const index = (focusedIndex + 1) % items.length;

    pushTracking({
      ...trackingData,
      currentPage: index + 1,
    });

    setFocusedIndex(index);
  }, [focusedIndex, items, pushTracking, trackingData]);

  return {
    previousSlide,
    nextSlide,
    focusedIndex,
    setFocusedIndex,
  };
}
