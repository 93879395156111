import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import capitalize from 'lodash/capitalize';

import { FilterOptionShape } from '../../../../shared/shapes';

import styles from './filter-toggle-button.module.scss';

function FilterToggleButton({ option, toggleOption }) {
  const themeStyleClass = `theme${capitalize(option.value)}`;

  return (
    <button
      className={classNames(styles.filterToggleButton, {
        [styles[themeStyleClass]]: option.value,
        [styles.default]: option.value,
        [styles.active]: option.active,
        [styles.disabled]: !option.enabled,
      })}
      onClick={toggleOption}
    >
      {option.label}
    </button>
  );
}

FilterToggleButton.propTypes = {
  option: FilterOptionShape,
  toggleOption: PropTypes.func,
};

export default FilterToggleButton;
