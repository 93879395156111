import React, { useState } from 'react';
import PropTypes from 'prop-types';
import nl2br from 'react-nl2br';
import classNames from 'classnames';

import { toPresentableDate } from '../../../shared/utils/date/date';
import { KnowledgeItemShape } from '../../../shared/shapes';
import { getTransformationMediaUrl } from '../../../shared/utils/url/url';
import { CTA_TYPE } from '../../../shared/hooks/use-tracking/data-layer-variables';
import { CONTENT_ITEM_TYPES } from '../../../shared/constants.mjs';

import ArrowBox from '../../../components/global/arrow-box/arrow-box';
import Link from '../../../components/global/link/link';

import styles from './content-item-grid.module.scss';

const CONTENT_ITEM_PICTURE_CONFIG = {
  w: 750,
  fm: 'jpg',
};

const TILE_DISPLAY_PINNED = 'pinned';

export function getContentItemUrl(link, slug, type) {
  switch (type[0]) {
    case CONTENT_ITEM_TYPES.KNOWLEDGE_ITEM:
      return (link && link.url) || `/${slug}`;
    case CONTENT_ITEM_TYPES.PRESS_RELEASE:
      return `/${slug}`;
    case CONTENT_ITEM_TYPES.BLOG:
      return `/knowledge/blog/${slug}`;
    case CONTENT_ITEM_TYPES.PODCAST:
      return `/knowledge/podcast/${slug}`;
    case CONTENT_ITEM_TYPES.VIDEO:
      return `/knowledge/video/${slug}`;
  }
}

function ContentItemGrid({ items, trackingDataEvent }) {
  const [highlightItemIndex, setHighlightItemIndex] = useState(null);

  return (
    <div className={styles.contentGrid}>
      {items.map(
        (
          {
            id,
            tileDisplay,
            headline,
            subheadline,
            picture,
            mediaType = '',
            mediaSubtype,
            link,
            date,
            eventDate,
            eventLocation,
            eventDuration,
            tags = [],
            slug,
            topic = '',
            type,
          },
          index
        ) => {
          const trackingData = {
            event: trackingDataEvent,
            title: headline,
            topic,
            media: mediaType,
          };

          const url = getContentItemUrl(link, slug, type);

          return (
            <div
              className={classNames(
                styles.contentTile,
                styles[mediaType.toLowerCase()],
                { [styles.pinned]: tileDisplay === TILE_DISPLAY_PINNED }
              )}
              key={id}
            >
              <div className={styles.tileInner}>
                <Link
                  href={url}
                  isExternalUrl={link && link.isExternalUrl}
                  trackingData={{
                    ...trackingData,
                    ctaType: CTA_TYPE.CONTENT_ITEM_IMAGE,
                  }}
                >
                  <a
                    className={classNames(styles.pictureArea, {
                      [styles.highlightItem]: index === highlightItemIndex,
                    })}
                    onMouseLeave={() => setHighlightItemIndex(null)}
                    onMouseEnter={() => setHighlightItemIndex(index)}
                  >
                    <picture>
                      <source
                        srcSet={
                          picture &&
                          getTransformationMediaUrl(
                            picture,
                            CONTENT_ITEM_PICTURE_CONFIG
                          )
                        }
                      />
                      <img
                        src={
                          picture &&
                          getTransformationMediaUrl(
                            picture,
                            CONTENT_ITEM_PICTURE_CONFIG
                          )
                        }
                        alt={picture && picture.title}
                        className={styles.picture}
                      />
                    </picture>

                    <div className={styles.overlay} />
                    <p className={styles.cta}>{mediaType}</p>

                    <div className={styles.arrowBox}>
                      <ArrowBox focused={index === highlightItemIndex} />
                    </div>
                  </a>
                </Link>

                <div className={styles.contentArea}>
                  <div className={styles.flagContainer}>
                    <div className={styles.flag}>
                      <p className={styles.flagText}>A</p>
                    </div>
                  </div>

                  <div className={styles.itemDetailsArea}>
                    <p className={styles.mediaSubtype}>{mediaSubtype}</p>
                    {date && (
                      <p className={styles.itemDate}>
                        {toPresentableDate(date)}
                      </p>
                    )}

                    {subheadline && (
                      <p className={styles.dotSeparator}>&middot;</p>
                    )}

                    <p className={styles.subheadline}>{subheadline}</p>
                  </div>

                  <Link
                    href={url}
                    isExternalUrl={link && link.isExternalUrl}
                    trackingData={{
                      ...trackingData,
                      ctaType: CTA_TYPE.CONTENT_ITEM_LINK,
                    }}
                  >
                    <a
                      className={styles.headlineArea}
                      onMouseEnter={() => setHighlightItemIndex(index)}
                      onMouseLeave={() => setHighlightItemIndex(null)}
                    >
                      <h2 className={styles.headline}>{nl2br(headline)}</h2>
                    </a>
                  </Link>

                  {eventDate && (
                    <div className={styles.eventDetailsArea}>
                      <dl className={styles.eventDetailsList}>
                        <div className={styles.eventDetail}>
                          <dt>Date</dt>
                          <dd>{eventDate}</dd>
                        </div>

                        <div className={styles.eventDetail}>
                          <dt>Location</dt>
                          <dd>{eventLocation}</dd>
                        </div>

                        <div className={styles.eventDetail}>
                          <dt>Start</dt>
                          <dd>{eventDuration}</dd>
                        </div>
                      </dl>
                    </div>
                  )}

                  <ul className={styles.tagsList}>
                    {tags.map(tag => (
                      <li className={styles.tag} key={id.concat(tag.id)}>
                        {tag.name}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          );
        }
      )}
    </div>
  );
}

ContentItemGrid.propTypes = {
  items: PropTypes.arrayOf(KnowledgeItemShape),
  trackingDataEvent: PropTypes.string.isRequired,
};

export default ContentItemGrid;
