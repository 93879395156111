import React from 'react';
import get from 'lodash/get';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import gfm from 'remark-gfm';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { INLINES, BLOCKS } from '@contentful/rich-text-types';

import { getTransformationMediaUrl } from '../../../shared/utils/url/url';
import { KnowledgeItemShape } from '../../../shared/shapes';
import { linkNode } from '../../../shared/utils/rich-text';
import {
  CTA_TYPE,
  EVENTS,
} from '../../../shared/hooks/use-tracking/data-layer-variables';
import Link from '../../global/link/link';
import KnowledgeHeroItem from '../knowledge-section/knowledge-hero-item/knowledge-hero-item';
import styles from './content-item-detail.module.scss';

/* eslint-disable react/display-name */
const RICH_TEXT_OPTIONS = {
  renderText: text => {
    return text.split('\n').reduce((children, textSegment, index) => {
      return [...children, index > 0 && <br key={index} />, textSegment];
    }, []);
  },
  renderNode: {
    ...linkNode,
    [INLINES.HYPERLINK]: node => {
      const url = get(node, 'data.uri', '');
      const labelText = get(node, 'content[0].value', '');

      return (
        <Link
          href={url}
          isExternalUrl
          trackingData={{
            event: EVENTS.CLICK_CONTENT_LINK,
            ctaType: CTA_TYPE.CONTENT_ITEM_LINK,
            label: url,
          }}
        >
          <a>{labelText}</a>
        </Link>
      );
    },
    [BLOCKS.EMBEDDED_ASSET]: node => {
      const url = get(node, 'data.target.fields.file.url', '');
      const label = get(node, 'data.target.fields.title', '');

      return (
        <picture>
          <source srcSet={url} />
          <img src={url} alt={label} />
        </picture>
      );
    },
  },
};

/* eslint-enable react/display-name */

/* eslint-disable react/display-name */
const MARKDOWN_RENDERERS = {
  // eslint-disable-next-line react/prop-types
  code: ({ language, value }) => {
    return <SyntaxHighlighter language={language}>{value}</SyntaxHighlighter>;
  },
  // eslint-disable-next-line react/prop-types
  li: ({ children }) => {
    return (
      <li>
        <div>{children}</div>
      </li>
    );
  },
  // eslint-disable-next-line react/prop-types
  table: ({ children }) => {
    return (
      <div className={styles.tableContainer}>
        <table>{children}</table>
      </div>
    );
  },
};
/* eslint-enable react/display-name */

const CONTENT_ITEM_PICTURE_CONFIG = {
  w: 1080,
  fm: 'jpg',
};

function ContentItemDetail(item) {
  return (
    <section className={styles.contentItemDetailSection}>
      <div className={styles.inner}>
        <div className={styles.headlineArea}>
          <KnowledgeHeroItem item={item} />
        </div>

        <article className={styles.article}>
          <picture>
            <source
              srcSet={
                item.picture &&
                getTransformationMediaUrl(
                  item.picture,
                  CONTENT_ITEM_PICTURE_CONFIG
                )
              }
            />
            <img
              className={styles.featuredPicture}
              src={
                item.picture &&
                getTransformationMediaUrl(
                  item.picture,
                  CONTENT_ITEM_PICTURE_CONFIG
                )
              }
              alt={item.picture && item.picture.title}
            />
          </picture>

          <div className={styles.contentArea}>
            <div className={styles.categories}>
              {item.tags && (
                <div className={styles.topics}>
                  <p className={styles.categoryTitle}>Topics</p>
                  <ul className={styles.tagsList}>
                    {item.tags.map(tag => (
                      <li className={styles.tag} key={item.id.concat(tag.id)}>
                        {tag.name}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>

            <div className={styles.content}>
              {item.content ? (
                documentToReactComponents(item.content, RICH_TEXT_OPTIONS)
              ) : (
                <ReactMarkdown
                  linkTarget="_blank"
                  plugins={[gfm]}
                  components={MARKDOWN_RENDERERS}
                >
                  {item.contentMd}
                </ReactMarkdown>
              )}
            </div>
          </div>
        </article>
      </div>
    </section>
  );
}

ContentItemDetail.displayName = 'ContentItemDetail';

ContentItemDetail.propTypes = {
  item: KnowledgeItemShape,
};

export default ContentItemDetail;
