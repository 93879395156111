import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';

import { RichTextShape } from '../../../shared/shapes';
import SectionIndicator from '../../../components/global/section-indicator/section-indicator';

import { getSubscriptionFormMarkup } from './subscription-form';
import styles from './pakcon-landing.module.scss';
import {
  SectionHeading,
  ExternalLink,
  LineBreak,
  Entry,
} from './rich-text-components';

const PAKCON_RICH_TEXT_OPTIONS = {
  renderNode: {
    [INLINES.HYPERLINK]: ExternalLink,
    [BLOCKS.HEADING_2]: SectionHeading,
    [BLOCKS.EMBEDDED_ENTRY]: Entry,
    [BLOCKS.HR]: LineBreak,
  },
};

function PakconLanding({ content, sponsors }) {
  return (
    <>
      <article className={styles.pakconLanding}>
        <section className={styles.contentArea}>
          {documentToReactComponents(content, PAKCON_RICH_TEXT_OPTIONS)}
        </section>

        <section className={styles.contentArea}>
          {documentToReactComponents(sponsors, PAKCON_RICH_TEXT_OPTIONS)}
        </section>

        <div className={styles.subscriptionFormHeading} id="subscription-form">
          <SectionIndicator headline={'Register for a Ticket'} />
        </div>
        <section
          className={styles.subscriptionForm}
          dangerouslySetInnerHTML={getSubscriptionFormMarkup()}
          suppressHydrationWarning
        />
      </article>
    </>
  );
}

PakconLanding.propTypes = {
  content: RichTextShape.isRequired,
  sponsors: RichTextShape.isRequired,
};

export default PakconLanding;
