const WORK_HOURS_PER_WEEK = 40;
const WORK_HOURS_PER_DAY = 8;
const MONTH_PER_YEAR = 12;

export const BASE_WORKDAYS = 365 - 52.5 * 2 - WORK_HOURS_PER_DAY;

export const DEFAULT_VALUES = {
  addonCostPercent: 20,
  costPersonalEquipmentPerAnnum: 500,
  daysPerAnnumExternalEvents: 5,
  daysPerAnnumInternalEvents: 5,
  daysSickLeave: 5,
  daysVacation: 25,
  eurPerSqmRentPerMonth: 15,
  hoursPerWeekOverhead: 4,
  hoursPerWeekRAndD: 0,
  hoursPerWeekTeamManagement: 2,
  idleTimePercent: 0,
  monthsLostInSearch: 8,
  overheadCosts: 10,
  salaryPerAnnum: 65000,
  sqmPerEmployeeOfficeSpace: 3,
  yearsUntilReplacement: 2.5,
};

export function getCalculatedValues({
  addonCostPercent,
  costPersonalEquipmentPerAnnum,
  daysPerAnnumExternalEvents,
  daysPerAnnumInternalEvents,
  daysSickLeave,
  daysVacation,
  eurPerSqmRentPerMonth,
  hoursPerWeekOverhead,
  hoursPerWeekRAndD,
  hoursPerWeekTeamManagement,
  idleTimePercent,
  monthsLostInSearch,
  overheadCosts,
  salaryPerAnnum,
  sqmPerEmployeeOfficeSpace,
  yearsUntilReplacement,
}) {
  // -- cost --

  const costOfReplacementProportion = yearsUntilReplacement
    ? monthsLostInSearch / (yearsUntilReplacement * MONTH_PER_YEAR)
    : 0;

  const overheadCostsProportion = overheadCosts ? 1 / overheadCosts : 0;

  const rentPerAnnum =
    sqmPerEmployeeOfficeSpace * eurPerSqmRentPerMonth * MONTH_PER_YEAR;
  const totalCostInfraPerAnnum = rentPerAnnum + costPersonalEquipmentPerAnnum;
  const infrastructureCostsProportion = salaryPerAnnum
    ? totalCostInfraPerAnnum / salaryPerAnnum
    : 0;

  const calcAddonCostPercent = addonCostPercent / 100;

  const costFactor =
    1 +
    calcAddonCostPercent +
    costOfReplacementProportion +
    overheadCostsProportion +
    infrastructureCostsProportion;

  // -- work --

  const overheadTasksProportion = hoursPerWeekOverhead / WORK_HOURS_PER_WEEK;
  const teamManagementProportion =
    hoursPerWeekTeamManagement / WORK_HOURS_PER_WEEK;
  const rAndDProportion = hoursPerWeekRAndD / WORK_HOURS_PER_WEEK;

  const totalAbsence =
    daysPerAnnumInternalEvents +
    daysPerAnnumExternalEvents +
    daysVacation +
    daysSickLeave;
  const totalAbsenseProportion =
    1 - (BASE_WORKDAYS - totalAbsence) / BASE_WORKDAYS;

  const calcIdleTimePercent = idleTimePercent / 100;

  const workPercent = Math.max(
    0,
    1 -
      totalAbsenseProportion -
      overheadTasksProportion -
      teamManagementProportion -
      rAndDProportion -
      calcIdleTimePercent
  );
  const workFactor = 1 / workPercent;

  // -- total --

  const totalFactor = costFactor * workFactor;
  const costPerEffectiveHour =
    (salaryPerAnnum * totalFactor) / BASE_WORKDAYS / WORK_HOURS_PER_DAY;

  const costPerHour = salaryPerAnnum / BASE_WORKDAYS / WORK_HOURS_PER_DAY;

  return {
    baseWorkdays: BASE_WORKDAYS,
    addonCostPercent,
    costOfReplacementPercent: costOfReplacementProportion * 100,
    overheadCostsPercent: overheadCostsProportion * 100,
    infrastructureCostsPercent: infrastructureCostsProportion * 100,
    costFactor,
    totalAbsensePercent: totalAbsenseProportion * 100,
    overheadTasksPercent: overheadTasksProportion * 100,
    teamManagementPercent: teamManagementProportion * 100,
    rAndDPercent: rAndDProportion * 100,
    workFactor,
    costPerHour,
    costPerEffectiveHour,
  };
}
