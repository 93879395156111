import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SlideDown from 'react-slidedown';
import classNames from 'classnames';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import { FaqItemShape } from '../../../shared/shapes';
import useTracking from '../../../shared/hooks/use-tracking/use-tracking';
import { EVENTS } from '../../../shared/hooks/use-tracking/data-layer-variables';

import styles from './faq-list.module.scss';

function FaqList({ items }) {
  const [expandedItemIds, setExpandedItemIds] = useState(new Set([]));
  const { pushTracking } = useTracking();

  const toggle = id =>
    setExpandedItemIds(prevIds => {
      const newIds = new Set(prevIds);
      newIds.has(id) ? newIds.delete(id) : newIds.add(id);
      return newIds;
    });

  return (
    <div className={styles.faqList}>
      <ul className={styles.inner}>
        {items &&
          items.map(({ id, question, answer }) => {
            const isExpanded = expandedItemIds.has(id);
            return (
              <li
                className={classNames(styles.faqListItem, {
                  [styles.expanded]: isExpanded,
                })}
                key={id}
              >
                <button
                  aria-expanded={isExpanded}
                  className={styles.questionContainer}
                  onClick={() => {
                    toggle(id);
                    pushTracking({
                      event: EVENTS.TOGGLE_FAQ,
                      label: question,
                      isOpenBefore: isExpanded,
                    });
                  }}
                >
                  <span className={styles.question}>{question}</span>
                  <div className={styles.toggle} />
                </button>

                <SlideDown
                  className={classNames(styles.slideDown, styles.faqItem)}
                  transitionOnAppear={false}
                >
                  {isExpanded && (
                    <div className={styles.answerContainer}>
                      {documentToReactComponents(answer)}
                    </div>
                  )}
                </SlideDown>
              </li>
            );
          })}
      </ul>
    </div>
  );
}

FaqList.propTypes = {
  items: PropTypes.arrayOf(FaqItemShape),
};

export default FaqList;
