import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './arrow-box.module.scss';

function ArrowBox({ onClick, focused }) {
  return (
    <div
      className={classNames(styles.arrowBox, { [styles.focused]: focused })}
      onClick={onClick}
    >
      <div className={styles.arrow} />
    </div>
  );
}

ArrowBox.propTypes = {
  focused: PropTypes.bool,
  onClick: PropTypes.func,
};

export default ArrowBox;
