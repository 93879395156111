import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { COOKIE_POLICIES } from '../../../services/cookies/cookies';
import { GDPR } from '../../../shared/content';
import Button, { MODIFIERS } from '../../global/button/button';
import { showToast } from '../../global/toast/toast';
import { CookieContext } from '../../../shared/context/cookie-context';
import Input from '../../global/form/input/input';
import styles from './cookie-settings.module.scss';

function CookieSettings({ content }) {
  const { cookie, setCookie } = useContext(CookieContext);
  const [value, setValue] = useState(cookie);

  const toggleValue = () =>
    setValue(
      value === COOKIE_POLICIES.ACCEPT_ALL
        ? COOKIE_POLICIES.DECLINE_ALL
        : COOKIE_POLICIES.ACCEPT_ALL
    );

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>{content.title}</h1>
      <div className={styles.form}>
        <p>{content.text}</p>

        <div className={styles.inputContainer}>
          <Input
            type="radio"
            checked={value === COOKIE_POLICIES.ACCEPT_ALL}
            onChange={toggleValue}
            value={COOKIE_POLICIES.ACCEPT_ALL}
            name="cookie-settings"
            label={GDPR.ACCEPT}
          />

          <Input
            type="radio"
            checked={value === COOKIE_POLICIES.DECLINE_ALL}
            onChange={toggleValue}
            value={COOKIE_POLICIES.DECLINE_ALL}
            name="cookie-settings"
            label={GDPR.DECLINE}
          />
        </div>
      </div>

      <div className={styles.buttonContainer}>
        <Button
          title={content.buttonText}
          onClick={() => {
            setCookie(value);
            showToast('Settings saved');
          }}
          modifiers={[MODIFIERS.PRIMARY, MODIFIERS.HOVER_BLUE, MODIFIERS.LARGE]}
        />
      </div>
    </div>
  );
}

CookieSettings.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.string,
    buttonText: PropTypes.string,
  }).isRequired,
};

export default CookieSettings;
