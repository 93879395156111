export function getSubscriptionFormMarkup() {
  return {
    __html: `<link href="//cdn-images.mailchimp.com/embedcode/classic-10_7.css" rel="stylesheet" type="text/css">
    <style type="text/css">
        #mc_embed_signup {
            background: #fff;
            clear: left;
            font: 14px Helvetica, Arial, sans-serif;
        }
    </style>
    <div id="mc_embed_signup">
        <form action="https://project-a.us5.list-manage.com/subscribe/post?u=b15035f944e9ff6d1437a5fe0&amp;id=2fbaadaec3" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
            <div id="mc_embed_signup_scroll">
                <h2>Register here for the Project A Knowledge Conference 2020</h2>
                <h3><u>I. General Information</u></h3>
                <div class="indicates-required"><span class="asterisk">*</span> indicates required</div>
                <div class="mc-field-group">
                    <label for="mce-EMAIL">Email Address <span class="asterisk">*</span>
                    </label>
                    <input type="email" value="" name="EMAIL" class="required email" id="mce-EMAIL">
                </div>
                <div class="mc-field-group">
                    <label for="mce-FNAME">First Name <span class="asterisk">*</span>
                    </label>
                    <input type="text" value="" name="FNAME" class="required" id="mce-FNAME">
                </div>
                <div class="mc-field-group">
                    <label for="mce-LNAME">Last Name <span class="asterisk">*</span>
                    </label>
                    <input type="text" value="" name="LNAME" class="required" id="mce-LNAME">
                </div>
                <div class="mc-field-group">
                    <label for="mce-MMERGE5">Would you please be so kind and share the link to your LinkedIn profile? </label>
                    <input type="text" value="" name="MMERGE5" class="" id="mce-MMERGE5">
                </div>
                <h3><u>II. Professional information and interest </u></h3>
                <p>The following details help us to understand what participants will expect from this year’s Project A Knowledge Conference. It allows us to contact interested participants and to help you get the best networking experience out of this event. The following details will be shared, with your consent, with our sponsors. </p>
                <div class="mc-field-group">
                    <label for="mce-MMERGE6">Which company do you work for? <span class="asterisk">*</span>
                    </label>
                    <input type="text" value="" name="MMERGE6" class="required" id="mce-MMERGE6">
                </div>
                <div class="mc-field-group">
                    <label for="mce-MMERGE7">What type of company is that? <span class="asterisk">*</span>
                    </label>
                    <select name="MMERGE7" class="required" id="mce-MMERGE7">
                        <option value=""></option>
                        <option value="I'm self-employed">I'm self-employed</option>
                        <option value="Startup">Startup</option>
                        <option value="Small / medium sized company">Small / medium sized company</option>
                        <option value="Digital unit of a corporation">Digital unit of a corporation</option>
                        <option value="Corporation">Corporation</option>
                        <option value="VC">VC</option>
                        <option value="Investor in a Project A fund">Investor in a Project A fund</option>
                        <option value="Investor in another VC">Investor in another VC</option>
                        <option value="Media">Media</option>
                        <option value="Other">Other</option>
    
                    </select>
                </div>
                <div class="mc-field-group">
                    <label for="mce-MMERGE8">Is your company part of the Project A portfolio? <span class="asterisk">*</span>
                    </label>
                    <select name="MMERGE8" class="required" id="mce-MMERGE8">
                        <option value=""></option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                        <option value="I work for Project A">I work for Project A</option>
                        <option value="I used to work for Project A (Alumni)">I used to work for Project A (Alumni)</option>
                        <option value="I'm not sure">I'm not sure</option>
    
                    </select>
                </div>
                <div class="mc-field-group">
                    <label for="mce-MMERGE9">What is your seniority level? <span class="asterisk">*</span>
                    </label>
                    <select name="MMERGE9" class="required" id="mce-MMERGE9">
                        <option value=""></option>
                        <option value="Intern">Intern</option>
                        <option value="Working student">Working student</option>
                        <option value="Trainee">Trainee</option>
                        <option value="Junior">Junior</option>
                        <option value="Medium">Medium</option>
                        <option value="Senior">Senior</option>
                        <option value="Assistant">Assistant</option>
                        <option value="Specialist">Specialist</option>
                        <option value="Associate">Associate</option>
                        <option value="Head of">Head of</option>
                        <option value="Director">Director</option>
                        <option value="VP">VP</option>
                        <option value="CEO / MD">CEO / MD</option>
                        <option value="Other C-level">Other C-level</option>
                        <option value="Partner">Partner</option>
                        <option value="Other">Other</option>
    
                    </select>
                </div>
                <div class="mc-field-group">
                    <label for="mce-MMERGE10">What is your functional area? <span class="asterisk">*</span>
                    </label>
                    <select name="MMERGE10" class="required" id="mce-MMERGE10">
                        <option value=""></option>
                        <option value="Product Management">Product Management</option>
                        <option value="Design">Design</option>
                        <option value="QA">QA</option>
                        <option value="Backend">Backend</option>
                        <option value="Frontend">Frontend</option>
                        <option value="DevOps / Infrastructure">DevOps / Infrastructure</option>
                        <option value="Marketing">Marketing</option>
                        <option value="Sales">Sales</option>
                        <option value="Data">Data</option>
                        <option value="HR / Recruiting">HR / Recruiting</option>
                        <option value="Finance">Finance</option>
                        <option value="Legal">Legal</option>
                        <option value="Brand">Brand</option>
                        <option value="Communications / PR">Communications / PR</option>
                        <option value="Office Management">Office Management</option>
                        <option value="Generalist / BizDev">Generalist / BizDev</option>
                        <option value="General Management">General Management</option>
                        <option value="Other">Other</option>
    
                    </select>
                </div>
                <div class="mc-field-group">
                    <label for="mce-MMERGE11">Why do you want to participate? What do you expect from the event? For investors: please share which companies do you invest in. <span class="asterisk">*</span>
                    </label>
                    <input type="text" value="" name="MMERGE11" class="required" id="mce-MMERGE11">
                </div>
                <div class="mc-field-group input-group">
                    <strong>Are you open for being interviewed for a job as part of the event or afterwards? <span class="asterisk">*</span>
                    </strong>
                    <ul>
                        <li><input type="radio" class="required" value="Yes, anytime" name="MMERGE14" id="mce-MMERGE14-0"><label for="mce-MMERGE14-0">Yes, anytime</label></li>
                        <li><input type="radio" value="Yes, but outside the event" name="MMERGE14" id="mce-MMERGE14-1"><label for="mce-MMERGE14-1">Yes, but outside the event</label></li>
                        <li><input type="radio" value="Not at all" name="MMERGE14" id="mce-MMERGE14-2"><label for="mce-MMERGE14-2">Not at all</label></li>
                    </ul>
                </div>
                <div class="mc-field-group input-group">
                    <strong>Is your company looking for funding and would you be interested in talking / learning about it as part of the event or afterwards? <span class="asterisk">*</span>
                    </strong>
                    <ul>
                        <li><input type="radio" class="required" value="Yes, anytime" name="MMERGE15" id="mce-MMERGE15-0"><label for="mce-MMERGE15-0">Yes, anytime</label></li>
                        <li><input type="radio" value="Yes, but outside the event" name="MMERGE15" id="mce-MMERGE15-1"><label for="mce-MMERGE15-1">Yes, but outside the event</label></li>
                        <li><input type="radio" value="Not at all" name="MMERGE15" id="mce-MMERGE15-2"><label for="mce-MMERGE15-2">Not at all</label></li>
                    </ul>
                </div>
                <p><u>Sponsors network:</u> We would like to give the sponsors for the Project A Knowledge Conference (check current sponsors above) advance access to the list of attendees (name, e-mail, company, seniority level, functional area, interest in funding or recruiting)  so that presentations and workshops can be tailored to the audience and for networking purposes (e.g. invitation to single sessions). Further information in our <a href="/knowledge-conference-privacy-policy">PAKCon privacy policy</a>.
                </p>
                <div class="mc-field-group input-group">
                  <ul>
                    <li><input type="radio" value="Yes" name="MMERGE3" id="mce-MMERGE3-0"><label for="mce-MMERGE3-0"><strong> Yes</strong></label><strong>, I agree with the transfer of my above-mentioned data to the sponsors and I agree to be contacted by email for the aforementioned purposes. I can revoke my consent at any time by sending an email to datenschutz@project-a.com. </strong>
                  </ul>
                </div>
                <h3><u>III. Organizational matters</u></h3>
                <div class="mc-field-group">
                    <label for="mce-MMERGE17">Did you receive an invitation code? If yes, please enter it here. </label>
                    <input type="text" value="" name="MMERGE17" class="" id="mce-MMERGE17">
                </div>
                <div class="mc-field-group input-group">
                    <strong>How did you learn about this application form <span class="asterisk">*</span>
                    </strong>
                    <ul>
                        <li><input type="radio" class="required" value="Email from Project A" name="MMERGE18" id="mce-MMERGE18-0"><label for="mce-MMERGE18-0">Email from Project A</label></li>
                        <li><input type="radio" value="LinkedIn" name="MMERGE18" id="mce-MMERGE18-1"><label for="mce-MMERGE18-1">LinkedIn</label></li>
                        <li><input type="radio" value="Other social media" name="MMERGE18" id="mce-MMERGE18-2"><label for="mce-MMERGE18-2">Other social media</label></li>
                        <li><input type="radio" value="Project A Podcast" name="MMERGE18" id="mce-MMERGE18-3"><label for="mce-MMERGE18-3">Project A Podcast</label></li>
                        <li><input type="radio" value="Other podcast / article" name="MMERGE18" id="mce-MMERGE18-4"><label for="mce-MMERGE18-4">Other podcast / article</label></li>
                        <li><input type="radio" value="Browsing the Project A website" name="MMERGE18" id="mce-MMERGE18-5"><label for="mce-MMERGE18-5">Browsing the Project A website</label></li>
                        <li><input type="radio" value="Somebody told me" name="MMERGE18" id="mce-MMERGE18-6"><label for="mce-MMERGE18-6">Somebody told me</label></li>
                        <li><input type="radio" value="Project A family Slack" name="MMERGE18" id="mce-MMERGE18-7"><label for="mce-MMERGE18-7">Project A family Slack</label></li>
                        <li><input type="radio" value="Other" name="MMERGE18" id="mce-MMERGE18-8"><label for="mce-MMERGE18-8">Other</label></li>
                    </ul>
                </div>
                <div class="mc-field-group input-group">
                    <strong>Do you want to receive further updates? </strong><br><i>(We will keep you updated about our Project A Events, special announcements, and the latest company news. You can always opt-out of these updates via the unsubscribe link in each mail.) </i>
                    <ul>
                        <li><input type="radio" value="Yes" name="MMERGE12" id="mce-MMERGE12-0"><label for="mce-MMERGE12-0">Yes</label></li>
                    </ul>
                </div>
                <p>Our Project A Knowledge Conference 2020 <a href="/knowledge-conference-privacy-policy">privacy policy</a> applies to all data processing in connection with Knowledge Conference 2020.</p>
                <div id="mce-responses" class="clear">
                    <div class="response" id="mce-error-response" style="display:none"></div>
                    <div class="response" id="mce-success-response" style="display:none"></div>
                </div> <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
                <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_b15035f944e9ff6d1437a5fe0_2fbaadaec3" tabindex="-1" value=""></div>
                <div class="clear"><input type="submit" value="Register" name="subscribe" id="mc-embedded-subscribe" class="button"></div>
            </div>
        </form>
    </div>
    <script type='text/javascript' src='//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js'></script>
    <script type='text/javascript'>
        (function($) {
            window.fnames = new Array();
            window.ftypes = new Array();
            fnames[0] = 'EMAIL';
            ftypes[0] = 'email';
            fnames[1] = 'FNAME';
            ftypes[1] = 'text';
            fnames[2] = 'LNAME';
            ftypes[2] = 'text';
            fnames[4] = 'PHONE';
            ftypes[4] = 'phone';
            fnames[5] = 'MMERGE5';
            ftypes[5] = 'text';
            fnames[6] = 'MMERGE6';
            ftypes[6] = 'text';
            fnames[7] = 'MMERGE7';
            ftypes[7] = 'dropdown';
            fnames[8] = 'MMERGE8';
            ftypes[8] = 'dropdown';
            fnames[9] = 'MMERGE9';
            ftypes[9] = 'dropdown';
            fnames[10] = 'MMERGE10';
            ftypes[10] = 'dropdown';
            fnames[11] = 'MMERGE11';
            ftypes[11] = 'text';
            fnames[14] = 'MMERGE14';
            ftypes[14] = 'radio';
            fnames[15] = 'MMERGE15';
            ftypes[15] = 'radio';
            fnames[17] = 'MMERGE17';
            ftypes[17] = 'text';
            fnames[18] = 'MMERGE18';
            ftypes[18] = 'radio';
            fnames[19] = 'MMERGE19';
            ftypes[19] = 'text';
            fnames[20] = 'MMERGE20';
            ftypes[20] = 'text';
            fnames[3] = 'MMERGE3';
            ftypes[3] = 'radio';
            fnames[12] = 'MMERGE12';
            ftypes[12] = 'radio';
        }(jQuery));
        var $mcj = jQuery.noConflict(true);
    </script>`,
  };
}
