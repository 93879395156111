import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Controller, useFormContext } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { GreenhouseQuestionShape } from '../../../../../shared/shapes';
import {
  useTranslatedContent,
  APPLICATION_FORM,
} from '../../../../../shared/content';

import CalendarIcon from '../../../../../shared/icons/calendar.svg';
import Label from '../label/label';
import Error from '../error/error';

import styles from './date-input.module.scss';

function DateInput({ question, minDate = false, maxDate = false, lang }) {
  const t = useTranslatedContent(APPLICATION_FORM, lang);

  const { required, description, label, name } = question;

  const {
    formState: { errors },
  } = useFormContext();
  const error = errors[name];

  return (
    <div
      className={classNames(styles.dateInput, {
        [styles.hasError]: error,
      })}
    >
      <label className={styles.label}>
        <div className={styles.labelText}>
          <Label text={description || label} required={required} lang={lang} />
        </div>

        <div className={styles.inputWrapper}>
          <Controller
            name={name}
            rules={{ required: required && t.VALIDATION_REQUIRED }}
            render={({ field: { onChange, value } }) => (
              <DatePicker
                className={styles.datePicker}
                onChange={date => onChange(date)}
                selected={value}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                dateFormat={'dd.MM.yyyy'}
                placeholderText={t.DATE_INPUT_PLACEHOLDER}
                minDate={minDate}
                maxDate={maxDate}
                showPopperArrow={false}
                autoComplete={'off'}
              />
            )}
          />
          <div className={styles.icon}>
            <CalendarIcon width={23} />
          </div>
        </div>
      </label>

      {error && <Error text={error.message} />}
    </div>
  );
}

DateInput.propTypes = {
  question: GreenhouseQuestionShape,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  lang: PropTypes.oneOf(['EN', 'DE']),
};

export default DateInput;
