import React from 'react';
import PropTypes from 'prop-types';

import { FilterGroupShape, KnowledgeItemShape } from '../../../shared/shapes';
import { useFilters } from '../../../shared/hooks/use-filters/use-filters';
import {
  CTA_TYPE,
  EVENTS,
} from '../../../shared/hooks/use-tracking/data-layer-variables';

import ExpandingFilters from '../../../components/global/expanding-filters/expanding-filters';
import ContentItemGrid from '../../../components/global/content-item-grid/content-item-grid';

import styles from './knowledge-section.module.scss';

function KnowledgeSection({ items: initialItems = [], initialFilterGroups }) {
  const [filterGroups, filteredItems, actions] = useFilters(
    initialFilterGroups,
    initialItems
  );

  return (
    <section className={styles.knowledgeSection}>
      <ExpandingFilters
        filterGroups={filterGroups}
        actions={actions}
        trackingCtaType={CTA_TYPE.FILTERS_KNOWLEDGE}
      />

      <ContentItemGrid
        items={filteredItems}
        trackingDataEvent={EVENTS.CLICK_CTA_KNOWLEDGE}
      />
    </section>
  );
}

KnowledgeSection.propTypes = {
  items: PropTypes.arrayOf(KnowledgeItemShape),
  initialFilterGroups: PropTypes.arrayOf(FilterGroupShape),
};

export default KnowledgeSection;
