import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';

import { VentureShape } from '../../../../shared/shapes';
import { useDisabledBackgroundScroll } from '../../../../shared/hooks/use-disabled-background-scroll/use-disabled-background-scroll';
import {
  CTA_TYPE,
  EVENTS,
} from '../../../../shared/hooks/use-tracking/data-layer-variables';
import useTracking from '../../../../shared/hooks/use-tracking/use-tracking';
import VentureTile from './venture-tile/venture-tile';
import VentureDetails from './venture-details/venture-details';

import styles from './venture-grid.module.scss';
import CrossIcon from './cross.svg';

function VentureGrid({ items }) {
  const [selectionIndex, setSelectionIndex] = useState(null);
  useDisabledBackgroundScroll(selectionIndex !== null);
  const { pushTracking } = useTracking();

  useEffect(() => {
    let { body } = document;

    if (selectionIndex !== null) {
      body.classList.add('header-behind');
    } else {
      body.classList.remove('header-behind');
    }
  }, [selectionIndex]);

  return (
    <div className={styles.items}>
      {items.map((item, index) => (
        <div className={styles.item} key={`venture-${item.id}`}>
          <div
            className={styles.tile}
            onClick={() => {
              setSelectionIndex(selectionIndex === index ? null : index);
              pushTracking({
                event: EVENTS.CLICK_VENTURE,
                ctaType: CTA_TYPE.VENTURE_OPEN_DETAIL,
                label: item.headline,
              });
            }}
          >
            <VentureTile venture={item} selected={selectionIndex === index} />
          </div>

          <CSSTransition
            in={selectionIndex === index}
            timeout={500}
            classNames={{
              enter: styles.expandEnter,
              enterActive: styles.expandEnterActive,
              exit: styles.expandExit,
              exitActive: styles.expandExitActive,
            }}
            unmountOnExit
          >
            <div className={styles.tileExpansion}>
              <div className={styles.dialog}>
                <div className={styles.dialogInner}>
                  <div className={styles.topArea}>
                    <button
                      className={styles.closeDetailsButton}
                      onClick={() => {
                        pushTracking({
                          event: EVENTS.CLICK_VENTURE,
                          ctaType: CTA_TYPE.VENTURE_CLOSE_DETAIL,
                          label: item.headline,
                        });
                        setSelectionIndex(null);
                      }}
                    >
                      <span className={styles.closeDetailsIcon}>
                        <CrossIcon width={33} />
                      </span>
                    </button>
                  </div>

                  {selectionIndex === index && (
                    <VentureDetails venture={items[selectionIndex]} />
                  )}
                </div>
              </div>
            </div>
          </CSSTransition>
        </div>
      ))}
    </div>
  );
}

VentureGrid.propTypes = {
  items: PropTypes.arrayOf(VentureShape),
};

export default VentureGrid;
