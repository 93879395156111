/**
 * Helper function to clean up contentful data object and limit nesting.
 * @param {Object || Array} data
 * @param {number} dataLevel
 * @returns {Object || Array}
 */
export function transformData(data, dataLevel) {
  if (Array.isArray(data)) {
    return data.map(item => transformData(item, dataLevel - 1));
  }

  if (typeof data === 'object' && data !== null) {
    if (data.nodeType === 'document') {
      return data;
    }

    if (dataLevel <= 0 && data.sys && data.sys.type !== 'Asset') {
      return {
        type: data.sys.type,
        linkType: data.sys.linkType,
        id: data.sys.id,
      };
    }

    if (data && data.fields) {
      const transformed = transformData(data.fields, dataLevel - 1);
      transformed.id = data.sys.id;
      transformed.updatedAt = data.sys.updatedAt;
      transformed.createdAt = data.sys.createdAt;

      if (data.sys.contentType) {
        transformed.contentType = data.sys.contentType.sys.id;
      }
      return transformed;
    }

    if (typeof data === 'undefined' || data === null) {
      return {};
    }

    return Object.entries(data).reduce(
      (obj, [key, value]) => ({
        ...obj,
        [key]: transformData(value, dataLevel - 1),
      }),
      {}
    );
  }

  return data;
}
