import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { INLINES, MARKS } from '@contentful/rich-text-types';
import classNames from 'classnames';
import { chunk } from 'lodash/fp';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React from 'react';
import nl2br from 'react-nl2br';
import {
  CTA_TYPE,
  EVENTS,
} from '../../../shared/hooks/use-tracking/data-layer-variables';
import { LinkShape, MediaShape, RichTextShape } from '../../../shared/shapes';
import { getTransformationMediaUrl } from '../../../shared/utils/url/url';

import ButtonLink from '../../global/button-link/button-link';
import ArrowLink from '../../../components/global/arrow-link/arrow-link';
import Link from '../../../components/global/link/link';
import styles from './teaser.module.scss';

const PORTRAIT_CONFIG = {
  w: 600,
  fm: 'jpg',
};

const RICH_TEXT_OPTIONS = {
  renderNode: {
    // eslint-disable-next-line react/display-name
    [INLINES.HYPERLINK]: node => {
      const url = get(node, 'data.uri', '');
      const labelText = get(node, 'content[0].value', '');

      return (
        <Link
          href={url}
          isExternalUrl
          trackingData={{
            event: EVENTS.CLICK_CTA_TEASER,
            ctaType: CTA_TYPE.TEASER,
            label: labelText,
          }}
        >
          <a className={classNames(styles.link, styles.active)}>{labelText}</a>
        </Link>
      );
    },
  },
  renderMark: {
    // eslint-disable-next-line react/display-name
    [MARKS.BOLD]: text => <strong>{text}</strong>,
  },
};

function Teaser({
  fullscreen = false,
  fullWidth = false,
  textSize,
  headlineTextSize = 'Large',
  ctaType = 'ArrowLink',
  headline,
  about,
  quote,
  cta,
  iconLinks,
  background,
  backgroundGallery,
}) {
  const galleryChunks = chunk(4, backgroundGallery);

  const linkProps = {
    ...cta,
    ctaType,
    trackingData: {
      event: EVENTS.CLICK_CTA_TEASER,
      ctaType: CTA_TYPE.TEASER,
      label: cta ? cta.label : '', // some CTAs are 'undefined'(i.e.: Quotes)
    },
  };

  return (
    <section className={styles.teaser}>
      <div
        className={classNames(styles.inner, {
          [styles.fullscreen]: fullscreen,
        })}
      >
        <div
          className={classNames(styles.text, { [styles.fullWidth]: fullWidth })}
        >
          {headline && (
            <h1
              className={classNames(styles.headline, {
                [styles.largeHeadline]: headlineTextSize === 'Large',
                [styles.mediumHeadline]: headlineTextSize === 'Medium',
              })}
            >
              {nl2br(headline)}
            </h1>
          )}

          {about && (
            <div
              className={classNames(styles.about, {
                [styles.aboutLarge]: textSize === 'Large',
              })}
            >
              {about.content
                ? documentToReactComponents(about, RICH_TEXT_OPTIONS)
                : about}
            </div>
          )}

          {quote && documentToReactComponents(quote, RICH_TEXT_OPTIONS)}

          {cta && (
            <div className={styles.cta}>
              {ctaType === 'Button' ? (
                <ButtonLink {...linkProps} />
              ) : (
                <ArrowLink {...linkProps} />
              )}
            </div>
          )}

          {iconLinks && (
            <div className={styles.iconLinks}>
              {iconLinks.map((link, index) => (
                <Link
                  href={link.url}
                  key={index}
                  isExternalUrl={link.isExternalUrl}
                  trackingData={{
                    event: EVENTS.CLICK_SOCIAL,
                    ctaType: CTA_TYPE.TEAM_MEMBER_SOCIAL,
                    label: link.name,
                  }}
                >
                  <a className={styles.iconLink}>
                    <picture>
                      <source srcSet={link.icon.file && link.icon.file.url} />
                      <img
                        src={link.icon.file && link.icon.file.url}
                        alt={link.icon.title}
                      />
                    </picture>
                  </a>
                </Link>
              ))}
            </div>
          )}
        </div>
      </div>

      {background && (
        <div className={styles.backgroundWrapper}>
          <div className={styles.background}>
            <picture>
              <source
                srcSet={getTransformationMediaUrl(background, PORTRAIT_CONFIG)}
              />
              <img
                src={getTransformationMediaUrl(background, PORTRAIT_CONFIG)}
                alt={background.description}
              />
            </picture>
          </div>
        </div>
      )}

      {backgroundGallery && (
        <div className={styles.backgroundGalleryWrapper} aria-hidden>
          <div className={styles.galleryInnerWrapper}>
            {galleryChunks.map((imgChunk, i) => {
              return (
                <div className={styles.galleryRow} key={`chunk-${i}`}>
                  {imgChunk.map((img, i) => {
                    return (
                      <div className={styles.galleryItem} key={i}>
                        <picture>
                          <source
                            srcSet={getTransformationMediaUrl(
                              img,
                              PORTRAIT_CONFIG
                            )}
                          />
                          <img
                            className={styles.galleryImage}
                            src={getTransformationMediaUrl(
                              img,
                              PORTRAIT_CONFIG
                            )}
                            alt=""
                          />
                        </picture>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </section>
  );
}

Teaser.propTypes = {
  fullscreen: PropTypes.bool,
  fullWidth: PropTypes.bool,
  textSize: PropTypes.oneOf(['Large', 'Normal']),
  headlineTextSize: PropTypes.oneOf(['Large', 'Medium']),
  ctaType: PropTypes.oneOf(['Button', 'ArrowLink']),
  headline: PropTypes.string,
  about: PropTypes.oneOfType([RichTextShape, PropTypes.string]),
  quote: RichTextShape,
  cta: LinkShape,
  iconLinks: PropTypes.arrayOf(LinkShape),
  background: MediaShape,
  backgroundGallery: PropTypes.arrayOf(MediaShape),
};

export default Teaser;
