import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import ReactMarkdown from 'react-markdown';
import 'react-slidedown/lib/slidedown.css';
import {
  CTA_TYPE,
  EVENTS,
} from '../../../shared/hooks/use-tracking/data-layer-variables';
import { CookieContext } from '../../../shared/context/cookie-context';
import { getTransformationMediaUrl } from '../../../shared/utils/url/url';
import ArrowLink from '../../global/arrow-link/arrow-link';
import { MODIFIERS } from '../../global/slider/slider';
import SliderControls from '../../global/slider/slider-controls';
import useSlider from '../../global/slider/useSlider';
import styles from './success-stories-slider.module.scss';

const PICTURE_CONFIG = {
  fm: 'jpg',
  bg: 'rgb:000000',
  w: 1140,
};

const QUERY_KEY = 'pav_cookie';

/**
 * Appends a query parameter to each success story link.
 * This query is used to set the cookie settings of
 * inside.project-a.com and determine whether it
 * should/should not show a cookie banner there.
 * This prevents users from interacting with multiple
 * cookie banners on both PAV website and inside.project-a.com,
 * which is meant to seem as if they belong to the same domain.
 */
const withCookieSettingQuery = (url, cookiePolicy) => {
  if (!cookiePolicy || !url.startsWith('https://inside.project-a.com'))
    return url;

  const modifiedURL = new URL(url);
  const query = new URLSearchParams(url.search);

  if (!query.has(QUERY_KEY)) {
    modifiedURL.searchParams.append(QUERY_KEY, cookiePolicy);
    return modifiedURL.toString();
  }
};

/* eslint-disable react/display-name */
const MARKDOWN_RENDERERS = {
  // eslint-disable-next-line react/prop-types
  blockquote: ({ children }) => {
    return <blockquote className={styles.quote}>{children}</blockquote>;
  },
  // eslint-disable-next-line react/prop-types
  p: ({ children }) => {
    return <p className={styles.summary}>{children}</p>;
  },
};
/* eslint-enable react/display-name */

function getSliderItemPosition(index, focusedIndex) {
  if (focusedIndex === 0) {
    return 0;
  }

  if (index < focusedIndex) {
    return -((index + 1) * 100);
  }

  return -(focusedIndex * 100);
}

function Testimonial({
  reviewer,
  reviewerRole,
  ventureName,
  quote,
  summary,
  link,
  linkLabel,
}) {
  const { cookie } = useContext(CookieContext);

  return (
    <div className={styles.testimonial}>
      <div className={styles.testimonialContent}>
        <cite className={styles.metadata}>
          {reviewer} | {reviewerRole}, {ventureName}
        </cite>
        <ReactMarkdown linkTarget="_blank" components={MARKDOWN_RENDERERS}>
          {quote}
        </ReactMarkdown>
        <ReactMarkdown linkTarget="_blank" components={MARKDOWN_RENDERERS}>
          {summary}
        </ReactMarkdown>
        {link && (
          <ArrowLink
            url={withCookieSettingQuery(link, cookie)}
            isExternalUrl
            label={linkLabel}
            trackingData={{
              event: EVENTS.SUCCESS_STORIES_SLIDER,
              ctaType: CTA_TYPE.SUCCESS_STORIES_FULL,
              label: `${reviewer} | ${ventureName}: Success Story`,
            }}
          />
        )}
      </div>
    </div>
  );
}
export default function SuccessStoriesSlider(props) {
  const { successStories } = props;
  const images = successStories.flatMap(story => story.images);
  const { previousSlide, nextSlide, focusedIndex, setFocusedIndex } = useSlider(
    successStories,
    EVENTS.SLIDER_NAVIGATION
  );

  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <div className={styles.testimonialContainer}>
          {successStories.map((story, index) => (
            <div
              key={story.id}
              className={classNames({
                [styles.hiddenQuote]: index != focusedIndex,
              })}
            >
              <Testimonial {...story} />
            </div>
          ))}
        </div>
        <div className={styles.slidesInner}>
          {images.map((image, index) => (
            <div
              key={image.id}
              style={{
                transform: `translateX(${getSliderItemPosition(
                  index,
                  focusedIndex
                )}%)`,
              }}
              className={classNames(styles.imgInner, {
                [styles.hiddenImage]: index < focusedIndex,
                [styles.nextImage]: index > focusedIndex,
                [styles.focused]: index === focusedIndex,
              })}
            >
              <picture>
                <source
                  srcSet={getTransformationMediaUrl(image, PICTURE_CONFIG)}
                />
                <img
                  alt=""
                  src={getTransformationMediaUrl(image, PICTURE_CONFIG)}
                />
              </picture>
            </div>
          ))}
        </div>
      </div>

      <SliderControls
        numberOfItems={successStories.length}
        focusedIndex={focusedIndex}
        onDotClick={index => {
          setFocusedIndex(index);
        }}
        previousSlide={() => {
          previousSlide();
        }}
        nextSlide={() => {
          nextSlide();
        }}
        modifiers={[MODIFIERS.MULTI, MODIFIERS.ADVANCED_CONTROLS]}
        trackingEventType={EVENTS.SLIDER_NAVIGATION}
      />
    </div>
  );
}

const propTypes = {
  reviewer: PropTypes.string.isRequired,
  reviewerRole: PropTypes.string.isRequired,
  ventureName: PropTypes.string.isRequired,
  quote: PropTypes.string.isRequired,
  summary: PropTypes.string,
  link: PropTypes.string.isRequired,
  linkLabel: PropTypes.string.isRequired,
};

SuccessStoriesSlider.propTypes = {
  successStories: PropTypes.arrayOf(PropTypes.shape(propTypes)),
};
Testimonial.propTypes = propTypes;
