import { compact, find, map } from 'lodash/fp';
import { CTA_TYPE } from '../../../shared/hooks/use-tracking/data-layer-variables';

/**
 * Groups employee list by the presence of the "picture" field.
 * @param {*} employeeList a list with type TeamMemberShape
 */
export function groupByPicture(employeeList) {
  return employeeList.reduce(
    (result, employee) => {
      if (employee.picture && employee.picture.file) {
        result.withImage.push(employee);
      } else {
        result.withoutImage.push(employee);
      }

      return result;
    },
    { withImage: [], withoutImage: [] }
  );
}

/**
 * Finds the first active option in each group.
 * Returns true if an active option is found, otherwise false.
 */
export function isAnyFilterOptionActive(filterGroups) {
  const getFirstActiveOptionFromGroup = group =>
    find(o => o.active, group.options);

  const activeOptions = compact(
    map(getFirstActiveOptionFromGroup, filterGroups)
  );

  return activeOptions.length > 0;
}

export function extractQuotesFromTeamMember(teamMembersList) {
  return teamMembersList.reduce((list, teamMember) => {
    if (!teamMember.quote) {
      return list;
    }

    return list.concat(teamMember.quote);
  }, []);
}

/**
 * Returns a new array that merges {@param allQuotes} into {@param teamMembersList} based on a hardcoded interval.
 * Injects a quote after every twelfth tile.
 * Change `NUM_TILES_BEFORE_QUOTE` to a different interval
 */
export function injectQuotesInEmployeeGroup(teamMembersList, allQuotes) {
  if (allQuotes.length === 0) {
    return teamMembersList;
  }

  if (teamMembersList.length === 0) {
    return [];
  }

  const NUM_TILES_BEFORE_QUOTE = 12;
  const result = [];

  let quoteIndex = 0;

  teamMembersList.forEach((teamMember, i, arr) => {
    result.push(teamMember);

    const isLastItem = i === arr.length - 1;
    const isItemBeforeQuote = (i + 1) % NUM_TILES_BEFORE_QUOTE === 0;

    if (!isLastItem && isItemBeforeQuote && quoteIndex < allQuotes.length) {
      result.push(allQuotes[quoteIndex]);
      quoteIndex++;
    }
  });

  return result;
}

/**
 * Determines which link is applied to the team member tiles
 */
export function getProfileLink(slug, customLink, about) {
  if (customLink) {
    return {
      link: customLink,
      isExternal: true,
      ctaType: CTA_TYPE.TEAM_CUSTOM_LINK,
    };
  }

  if (about) {
    return {
      link: `/team/${slug}`,
      isExternal: false,
      ctaType: CTA_TYPE.TEAM_BIO_PAGE,
    };
  }

  return null;
}
