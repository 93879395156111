import React from 'react';
import PropTypes from 'prop-types';

import { FilterGroupShape, JobShape } from '../../../shared/shapes';
import { useFilters } from '../../../shared/hooks/use-filters/use-filters';

import JobTableFilters from './job-table-filters/job-table-filters';
import JobTableRows from './job-table-rows/job-table-rows';

import styles from './job-list.module.scss';

function JobList({ headline, items: initialItems, initialFilterGroups }) {
  const [filterGroups, items, actions] = useFilters(
    initialFilterGroups,
    initialItems
  );

  return (
    <div className={styles.jobList}>
      <h2 className={styles.headline}>{headline}</h2>

      <table className={styles.jobs}>
        <JobTableFilters filterGroups={filterGroups} actions={actions} />
        <JobTableRows items={items} />
      </table>
    </div>
  );
}

JobList.propTypes = {
  headline: PropTypes.string,
  items: PropTypes.arrayOf(JobShape),
  initialFilterGroups: PropTypes.arrayOf(FilterGroupShape),
};

export default JobList;
